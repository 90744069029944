export const kwpPerPanel = 0.525;
export const panelSideLength = 2;
export const panelSpacing = 0.2;
export const tileSize = panelSideLength + panelSpacing;

export class MatrixSize {
  width: number;
  height: number;
  lastKwp: number;
  area: number;
  x: number;
  y: number;
  kwhMode: boolean;
  consumption: number;

  constructor(consumption: number, kwhMode: boolean, latitude: number) {
    this.width = 0;
    this.height = 0;
    this.lastKwp = 0;
    this.area = 0;
    this.x = 0;
    this.y = 0;
    this.kwhMode = kwhMode;
    this.consumption = consumption;
    if (kwhMode) this.update(latitude);
    else this.matrixDimensionsEstimate(consumption, kwpPerPanel);
  }

  update(latitude: number): void {
    if (!this.kwhMode) return;
    const cosLat = Math.cos((latitude * 2 * Math.PI) / 360.0);
    const kwp = Math.max(Math.round(this.consumption / (0.9 * (1900.0 * cosLat + 300.0))), 1);
    if (Math.abs(kwp - this.lastKwp) < kwpPerPanel) return;
    this.lastKwp = kwp;
    this.matrixDimensionsEstimate(kwp, kwpPerPanel);
  }

  matrixDimensionsEstimate(kwp: number, kwpPerPanel: number): void {
    const x = Math.sqrt(kwp / (Math.sqrt(2) * kwpPerPanel));
    this.x = Math.max(1, Math.round(x));
    this.width = tileSize * this.x;
    this.y = Math.max(1, Math.round(x * Math.sqrt(2)));
    this.height = tileSize * this.y;
    this.area = this.width * this.height;
  }
}
