import React from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import Footer from '../molecules/Footer';

const SiteLayout: React.FC<{children}> = ({ children }) => {
  return (
    <>
      <RemoveScroll allowPinchZoom={false}> </RemoveScroll>
      <div className="relative w-full h-full">
        <main className="w-full mx-auto">{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default SiteLayout;
