import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

type FormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

type Props = {
  control: Control<FormInputs>;
  errors: FieldErrors<FormInputs>;
  id: 'firstName' | 'lastName' | 'email' | 'phone';
  label: string;
};

const ContactInput: React.FC<Props> = ({ control, errors, id, label }) => {
  return (
    <div className="w-full">
      <Controller
        render={({ field }) => (
          <>
            <div className="text-xs font-normal wh3:text-sm wh4:text-base wh5:text-xl">{label}</div>
            <input
              type="text"
              name={id}
              id={id}
              className={
                'text-xs h-6 wh3:h-8 wh4:h-8 wh5:h-12 wh5:text-base wh3:text-sm w-full px-2 font-normal mt-0 rounded wh4:rounded-lg bg-white focus:outline-none font-arial focus:ring-1 focus:ring-secondary-100'
              }
              onChange={(e) => {
                field.onChange(e.currentTarget.value);
              }}
              value={field.value ?? ''}
              autoComplete="off"
              onBlur={field.onBlur}
            />
          </>
        )}
        name={id}
        control={control}
      />
      <div className={`${errors[id] ? 'pb-0.5 wh-1:pb-2' : 'pb-5 wh-1:pb-8 wh5:pb-10'}`}></div>
      <ErrorMessage
        errors={errors}
        name={id}
        render={({ message }) => (
          <p className="w-full pb-2 leading-none text-xs2 wh-1:text-xs wh5:text-base text-alert font-arial">
            {message}
          </p>
        )}
      />
    </div>
  );
};

export default ContactInput;
