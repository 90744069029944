import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { RemoveScroll } from 'react-remove-scroll';

type FormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

type Props = {
  control: Control<FormInputs>;
  errors: FieldErrors<FormInputs>;
  id: 'firstName' | 'lastName' | 'email' | 'phone';
  label: string;
};

const ContactPhoneInput: React.FC<Props> = ({ control, errors, id, label }) => {
  return (
    <div className="w-full">
      <Controller
        render={({ field }) => (
          <>
            <div className="text-xs font-normal wh3:text-sm wh4:text-base wh5:text-xl">{label}</div>

            <RemoveScroll enabled={true}>
              <PhoneInput
                country={'no'}
                value={field.value ?? ''}
                onChange={(phone) => field.onChange(phone)}
                onBlur={field.onBlur}
                enableSearch={true}
              />
            </RemoveScroll>
          </>
        )}
        name={id}
        control={control}
      />
      <div className={`${errors[id] ? 'pb-0.5 wh-1:pb-2' : 'pb-5 wh-1:pb-8 wh5:pb-10'}`}></div>
      <ErrorMessage
        errors={errors}
        name={id}
        render={({ message }) => (
          <p className="w-full pb-2 leading-none text-xs2 wh-1:text-xs wh5:text-base text-alert font-arial">
            {message}
          </p>
        )}
      />
    </div>
  );
};

export default ContactPhoneInput;
