import React from 'react';

import { Route, Routes } from 'react-router-dom';

import Main from './pages/Main';

export interface SmartQuoterRoute {
  route: string;
  component: React.FC;
}

export const smartQuoterRoutes = {
  '/': Main,
} as const;

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<Main />} />
    </Routes>
  );
};
