import React from 'react';
import { UseFetch } from 'use-http';

import { WaveQueryResponse } from '../../api/client/waveQuery';
import QuestionMark from '../atoms/QuestionMark';
import { Modal } from './Modal';
import { PolarChart } from './PolarChart';

type Props = {
  waveQuery: UseFetch<WaveQueryResponse>;
  windrose: PolarChart | undefined;
};

export const WindChart: React.FC<Props> = ({ waveQuery, windrose }) => {
  return (
    <>
      {(waveQuery.error && (
        <div className="w-full mx-auto mt-10 text-lg text-center">
          Environmental data is currently unavailable for this location.
        </div>
      )) ||
        ((waveQuery.loading || (!waveQuery.data && !waveQuery.error)) && (
          <div className="flex flex-col max-w-screen-lg mx-auto mt-20">
            <div className="mx-auto text-xl">Calculating wind data</div>
            <div className="mx-auto mt-4">
              <img src="/img/spinner.svg" alt="Loading" />
            </div>
          </div>
        )) ||
        (waveQuery.data && !waveQuery.error && (
          <>
            <div className="flex flex-col items-center w-full max-h-full ">
              <Modal
                title="Wind"
                text={
                  'The wind chart presents average hourly wind speeds from the 2014-2020 period. This data is obtained from the Era5 dataset from ECMWF. ' +
                  'The chart maps the data as incoming wind, meaning sector 0 shows the winds coming from the north. ' +
                  'The data is grouped into categories based on the wind speed. Individual categories can be toggled by clicking on the legend. ' +
                  'Below the chart are the highest expected winds for given time spans, that were extrapolated from the data.'
                }
                trigger={
                  <button
                    type="button"
                    className="flex z-10 flex-row text-gray-800 items-center wh0:pb-0.5 leading-none wh0:leading-tight wh2:pb-2 text-base wh0:text-lg mx-auto wh2:text-xl wh4:text-3xl wh6:text-4xl underline hover:cursor-pointer focus:outline-none active:border-transparent focus:ring-1 focus:ring-gray-400"
                  >
                    <span className="pr-1">Wind Chart</span>
                    <span className="inline-block">
                      <QuestionMark className="w-3 h-3 wh0:w-4 wh0:h-4 wh4:h-6 wh4:w-6 wh6:h-7 wh6:w-7" />
                    </span>
                  </button>
                }
              />
              <div className="relative max-h-full mx-auto w-72 wh0:w-96 wh4:w-156 wh7:w-120">
                {windrose?.render()}
              </div>
              <div className="flex flex-row mx-8 leading-tight text-center text-xs2 wh0:text-xs wh0:flex-col wh1:text-sm wh0:w-56 wh5:text-base wh3:mt-2">
                <div className="mx-1">
                  5-year wind: {waveQuery.data.returnPeriodWinds[0].toFixed(2)}m/s
                </div>
                <div className="mx-1">
                  10-year wind: {waveQuery.data.returnPeriodWinds[1].toFixed(2)}m/s
                </div>
                <div className="mx-1">
                  25-year wind: {waveQuery.data.returnPeriodWinds[2].toFixed(2)}m/s
                </div>
                <div className="mx-1">
                  50-year wind: {waveQuery.data.returnPeriodWinds[3].toFixed(2)}m/s
                </div>
              </div>
            </div>
          </>
        ))}
    </>
  );
};
