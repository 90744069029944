import React from 'react';

interface Props {
  className: string;
}

const QuestionMark: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      aria-hidden="true"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.5" cy="6.5" r="6" stroke="black" />
      <path
        d="M6.95 8H5.87C5.87 7.54 5.93 7.18 6.05 6.92C6.17667 6.65333 6.40667 6.38333 6.74 6.11L7.13 5.8C7.29667 5.66 7.43333 5.51667 7.54 5.37C7.65333 5.22333 7.71 5.04667 7.71 4.84C7.71 4.53333 7.61 4.28667 7.41 4.1C7.21 3.90667 6.93 3.81 6.57 3.81C6.16333 3.81 5.86333 3.94333 5.67 4.21C5.48333 4.47667 5.38667 4.81 5.38 5.21H4.29C4.29667 4.83667 4.36333 4.51 4.49 4.23C4.61667 3.95 4.78333 3.72 4.99 3.54C5.19667 3.36 5.44 3.22667 5.72 3.14C6 3.04667 6.29333 3 6.6 3C6.90667 3 7.19667 3.04 7.47 3.12C7.75 3.2 7.99 3.31667 8.19 3.47C8.39 3.62333 8.55 3.81 8.67 4.03C8.79 4.25 8.85 4.49667 8.85 4.77C8.85 5.17 8.77 5.48 8.61 5.7C8.45 5.91333 8.25333 6.11667 8.02 6.31L7.63 6.63C7.38333 6.83 7.20667 7.02667 7.1 7.22C7 7.41333 6.95 7.67333 6.95 8ZM7 10H5.84V8.88H7V10Z"
        fill="black"
      />
    </svg>
  );
};

export default QuestionMark;
