import React from 'react';

interface Props {
  className: string;
}

const Up: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      aria-hidden="true"
      width="19"
      height="10"
      viewBox="0 0 19 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="1.46824" y1="9.07151" x2="10.675" y2="0.752424" stroke="black" strokeWidth="2" />
      <line x1="18.2981" y1="9.0647" x2="9.34406" y2="0.795002" stroke="black" strokeWidth="2" />
    </svg>
  );
};

const Right: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      aria-hidden="true"
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.6812 5.38764L0 1.76206L7.39102e-08 0L10 3.74503C9.99226 4.33002 9.98452 4.91501 9.97677 5.5"
        fill="#101010"
      />
      <path
        d="M9.6812 5.61236L4.77465e-07 9.23794L4.03555e-07 11L10 7.25497C9.99226 6.66998 9.98452 6.08499 9.97677 5.5"
        fill="#101010"
      />
    </svg>
  );
};

const LeftWide: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      aria-hidden="true"
      width="11"
      height="19"
      viewBox="0 0 11 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.96754 1.46946L1.95904 9.16976L9.13073 17"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="miter"
      />
    </svg>
  );
};

export default {
  Right,
  Up,
  LeftWide,
};
