import React from 'react';
import { Tooltip } from './Tooltip';

type Props = {
  label: string | React.ReactNode;
  data: string | React.ReactNode;
  tooltipID?: string | undefined;
  hoverText?: string | React.ReactNode | undefined;
};

export const ResultField: React.FC<Props> = ({ label, data, hoverText, tooltipID }) => {
  return (
    <div className="flex flex-row-reverse wh0:flex-col items-center w-32 wh0:w-40 wh0:px-2 py-0.5 wh1:mt-1 wh2:mt-2 wh7:w-52">
      <span className="px-1 text-center w-14 wh0:w-auto text-xs2 wh1:text-xs wh3:text-sm wh5:text-xl wh7:text-3xl wh0:px-0">
        {data}
      </span>
      <div className="wh1:mt-0.5 wh7:mt-2 text-xs2 leading-2 wh1:leading-5 wh1:text-xs wh3:text-sm wh5:text-lg wh7:text-2xl text-gray-600 wh0:w-36 wh7:w-44">
        {label}
        {hoverText && (
          <Tooltip
            tooltipID={'resultfield' + tooltipID}
            message={hoverText}
            svgSize="wh7:w-5 wh7:h-5 wh5:w-4 wh5:h-4 wh3:w-3 wh3:h-3 w-2 h-2"
          />
        )}
      </div>
    </div>
  );
};
