import { RefObject, useEffect, useRef, useState } from 'react';
import { useGoogleMapsReady } from './google-maps';

export const mapOptions: google.maps.MapOptions = {
  streetViewControl: false,
  disableDoubleClickZoom: true,
  zoomControl: true,
  fullscreenControl: false,
  mapTypeControl: true,
  clickableIcons: false,
  gestureHandling: 'greedy',
  draggable: true,
  mapTypeId: 'hybrid',
  scaleControl: true,
};

export const mapOptionsDisabled: google.maps.MapOptions = {
  streetViewControl: false,
  disableDoubleClickZoom: true,
  zoomControl: true,
  fullscreenControl: false,
  mapTypeControl: true,
  clickableIcons: false,
  gestureHandling: 'greedy',
  draggable: false,
  mapTypeId: 'hybrid',
  scaleControl: true,
};

export type MapWithMarkers = {
  domRef: RefObject<HTMLDivElement>;
  mapObject: google.maps.Map<Element> | null;
  maxZoomService: google.maps.MaxZoomService | null;
  marker: google.maps.Marker | null;
  waveMarker: google.maps.Marker | null;
  polygon: google.maps.Polygon | null;
};

export const useMap = (): MapWithMarkers => {
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);
  const [waveMarker, setWaveMarker] = useState<google.maps.Marker | null>(null);
  const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);
  const mapsReady: boolean = useGoogleMapsReady();
  const mapDomRef = useRef<HTMLDivElement>(null);

  const [mapObject, setMapObject] = useState<google.maps.Map | null>(null);
  const [maxZoomService, setMaxZoomService] = useState<google.maps.MaxZoomService | null>(null);

  useEffect(
    // Map object initialization
    () => {
      if (!mapsReady || mapObject !== null || mapDomRef.current === null) {
        return;
      }
      const map = new google.maps.Map<HTMLDivElement>(mapDomRef.current, mapOptions);

      const newWaveMarker = new google.maps.Marker({
        map: map,
        clickable: true,
        icon: {
          path:
            'M7.61261 25.9269C10.4987 25.9269 11.9776 21.9418 11.9776 21.9418C11.9776 21.9418 13.4087 25.9269 16.3187 25.9269C19.2287 25.9269 20.6837 21.9418 20.6837 21.9418C20.6837 21.9418 22.6273 25.9148 25.0248 25.9269C27.4355 25.939 29.4136 21.9418 29.4136 21.9418C29.4136 21.9418 30.8447 25.9269 33.7547 25.9269M7.61261 16.6873C10.4987 16.6873 11.9776 12.7023 11.9776 12.7023C11.9776 12.7023 13.4087 16.6873 16.3187 16.6873C19.2287 16.6873 20.6837 12.7023 20.6837 12.7023C20.6837 12.7023 22.6273 16.6753 25.0248 16.6873C27.4355 16.6994 29.4136 12.7023 29.4136 12.7023C29.4136 12.7023 30.8447 16.6873 33.7547 16.6873M20.733 1.15094C30.5124 1.17172 39.371 8.65201 39.371 18.6846C39.371 22.4859 36.569 28.206 34.2958 31.977C30.5821 38.1373 20.7519 57.8575 20.7519 57.8575C20.7519 57.8575 10.8019 38.172 6.92067 31.977C4.64651 28.3471 1.99634 22.4859 1.99634 18.6846C1.99634 8.69235 10.9325 1.13012 20.733 1.15094Z',
          fillColor: '#5a62ba',
          fillOpacity: 1,
          strokeWeight: 2,
          strokeColor: '#cbd3f0',
          rotation: 0,
          scale: 0.8,
          anchor: new google.maps.Point(19, 57), //half the width and full height of the symbol to anchor the the marker's pointy bit
        },
      });
      const infoWindow = new google.maps.InfoWindow({ maxWidth: 400 });
      newWaveMarker.addListener('click', () => {
        infoWindow.close();
        infoWindow.setContent(
          'Our datasets do not contain water at the center of the installation, so the wave data was calculated for this point.'
        );
        infoWindow.open(map, newWaveMarker);
      });

      setMapObject(map);
      setMarker(
        new google.maps.Marker({
          map: map,
          clickable: false,
        })
      );
      setWaveMarker(newWaveMarker);
      setPolygon(
        new google.maps.Polygon({
          strokeColor: '#FFFFAA',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FFFFAA',
          fillOpacity: 0.35,
          map: map,
        })
      );
      setMaxZoomService(new google.maps.MaxZoomService());
      return () => {
        map.unbindAll();
      };
    },
    [mapsReady]
  );

  return { domRef: mapDomRef, mapObject, maxZoomService, marker, waveMarker, polygon };
};
