import React, { useEffect, useState } from 'react';
import useDebounce from '../../hooks/useDebounce';
import { useAutocompleteService } from '../../api/google-maps/autocompleteService';
import { useLocation } from 'react-router-dom';

type Place = {
  name: string | undefined;
  placeId: string | undefined;
};

interface LocationInputProps {
  id: string;
  onBlur: () => void;
  onChange: (newValue: Place) => void;
  value?: Place;
  clearErrors: () => void;
  previousValue: string | null;
}

const LocationInput: React.FC<LocationInputProps> = ({
  id,
  onBlur,
  onChange,
  value,
  clearErrors,
  previousValue,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);

  const autoComplete = useAutocompleteService();

  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [placeIds, setPlaceIds] = useState<string[]>([]);

  const [_locationInput, _setLocationInput] = React.useState(value?.name ?? '');
  const locationInput = useDebounce<string>(_locationInput, 300);

  const loc = useLocation();

  //fill out location field if we get a location from sunlitsea.no
  useEffect(() => {
    const location = new URLSearchParams(loc.search).get('location');
    if (autoComplete !== null && location) {
      autoComplete.getPlacePredictions({ input: location }, (result) => {
        setSuggestions(result?.map((e) => e.description) ?? []);
        setPlaceIds(result?.map((e) => e.place_id) ?? []);
        setShowSuggestions(true);
      });
      onChange({ placeId: undefined, name: location });
    }
  }, [autoComplete]);

  useEffect(() => {
    if (autoComplete !== null && locationInput.length > 1) {
      autoComplete.getPlacePredictions({ input: locationInput }, (result) => {
        setSuggestions(result?.map((e) => e.description) ?? []);
        setPlaceIds(result?.map((e) => e.place_id) ?? []);
      });
    } else {
      setSuggestions([]);
    }
  }, [locationInput]);

  useEffect(() => {
    if (previousValue) {
      _setLocationInput(previousValue);
      onChange({ placeId: value?.placeId, name: previousValue });
    }
  }, [previousValue]);

  return (
    <div className={'flex flex-col relative w-full'}>
      <input
        type="text"
        name="location"
        id={id}
        className={
          'w-full px-4 pt-1 font-normal rounded wh5:h-14 h-8 wh1:h-11 bg-secondary-50 focus:outline-none font-arial text-sm wh5:text-lg focus:ring-1 focus:ring-secondary-300'
        }
        onChange={(e) => {
          const newVal = e.currentTarget.value;
          _setLocationInput(newVal);
          onChange({ placeId: undefined, name: newVal });
          clearErrors();
        }}
        value={value?.name ?? ''}
        autoComplete="off"
        onFocus={() => {
          setShowSuggestions(true);
        }}
        onBlur={() => {
          if (value?.name == '') {
            onChange({ placeId: undefined, name: undefined });
            clearErrors();
          }
          onBlur();
          setShowSuggestions(false);
        }}
        onKeyDown={(e) => {
          if (e.key == 'Enter') {
            e.preventDefault();
            if (suggestions && suggestions.length > 0) {
              onChange({
                name: suggestions[0],
                placeId: placeIds[0],
              });
              clearErrors();
              setShowSuggestions(false);
            }
          } else if (e.key == 'Escape') {
            setShowSuggestions(false);
          } else {
            setShowSuggestions(true);
          }
        }}
      />
      {suggestions.length > 0 && showSuggestions && (
        <ul className="absolute z-50 w-full mt-12 bg-gray-600 rounded outline-none">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className={
                'text-xs wh1:text-sm wh5:text-lg cursor-pointer w-full py-1 px-4 my-0 select-none font-normal text-white rounded bg-gray-600 hover:bg-gray-500 font-arial'
              }
              onMouseDown={() => {
                clearErrors();
                onChange({
                  name: suggestions[index],
                  placeId: placeIds[index],
                });
                setShowSuggestions(false);
              }}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LocationInput;
