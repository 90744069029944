import React from 'react';

import { Link } from 'react-router-dom';
import LogoWithName from '../atoms/LogoWithName';

const Header: React.FC = () => {
  return (
    <header className="inset-x-0 top-0 z-50 block">
      <nav className="flex items-center justify-start w-full h-6 px-2 pt-1 wh1:px-12 wh1:py-3 wh1:h-10 wh4:h-20">
        <Link
          to="/"
          aria-label="Main page"
          className="inline-block border-transparent focus:outline-none"
        >
          <LogoWithName
            containerClassName="w-auto h-4 wh4:h-6"
            svgClassName="wh4:h-6 h-4 mx-auto wh1:m-auto"
            textClassName="wh1:mt-0.5 text-xl leading-none wh1:leading-tight wh4:text-3xl font-bold tracking-widest text-black"
          />
        </Link>
      </nav>
    </header>
  );
};

export default Header;
