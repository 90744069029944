import React, { useEffect, useState } from 'react';

import { quoteManualPath, QuoteManualResponse } from '../../api/client/quoteQuery';
import { wavePath, WaveQueryResponse } from '../../api/client/waveQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import { UseFetch } from 'use-http';
import { Modal } from '../molecules/Modal';
import { prettyNumber } from '../../utils/formatting';
import {
  computeDiagonalPoint,
  computePolygonPathsDirect,
  usePanelCoordinates,
} from '../../utils/geoOps';
import { MapWithMarkers } from '../../api/google-maps/useMap';
import { tileSize } from '../../utils/MatrixSize';
import ReactTooltip from 'react-tooltip';
import { ResultField } from '../atoms/ResultField';
import { BackButton } from './../atoms/BackButton';
import { CircledNumber } from '../atoms/CircledNumber';
import { WaterCoverage } from './../atoms/WaterCoverage';
import { isIOS, isChrome, isDesktop } from 'react-device-detect';
import { monthlyKwhPath, MonthlyKwhResponse } from '../../api/client/monthlyKwhQuery';

type Props = {
  map: MapWithMarkers;
  panels: google.maps.Polygon[];
  setPanels: React.Dispatch<React.SetStateAction<google.maps.Polygon[]>>;
  waveQuery: UseFetch<WaveQueryResponse>;
  monthlyKwhQuery: UseFetch<MonthlyKwhResponse>;
  quoteQuery: UseFetch<QuoteManualResponse>;
};

const Result: React.FC<Props> = ({
  map,
  panels,
  setPanels,
  waveQuery,
  monthlyKwhQuery,
  quoteQuery,
}) => {
  const loc = useLocation();
  const navigate = useNavigate();
  const [consumption] = useState(Number(new URLSearchParams(loc.search).get('consumption')));
  const [lat] = useState(Number(new URLSearchParams(loc.search).get('lat')));
  const [lng] = useState(Number(new URLSearchParams(loc.search).get('lng')));
  const [rot] = useState(Number(new URLSearchParams(loc.search).get('rot')));
  const [place] = useState(new URLSearchParams(loc.search).get('place'));
  const [zoom] = useState(Number(new URLSearchParams(loc.search).get('zoom')));
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [extraPanels, setExtraPanels] = useState(0);
  const [n, setN] = useState<number | null>(null);
  const [s, setS] = useState<number | null>(null);
  const [w, setW] = useState<number | null>(null);
  const [e, setE] = useState<number | null>(null);
  const [kwhMode] = useState(new URLSearchParams(loc.search).get('kwhMode') === 'true');
  const [waveDataRenewed, setWaveDataRenewed] = useState(false);

  const page = Number(new URLSearchParams(loc.search).get('page'));

  //init map
  useEffect(() => {
    if (page >= 2 && map.mapObject) {
      map.mapObject.setCenter({ lat: lat, lng: lng });
      map.mapObject.setZoom(zoom);
    }
  }, [map.mapObject, page]);

  //wait for map to init and then extract bounds
  useEffect(() => {
    const timeoutReference = setTimeout(() => {
      const bounds = map.mapObject?.getBounds();
      if (bounds) {
        setN(bounds.getNorthEast().lat());
        setS(bounds.getSouthWest().lat());
        setW(bounds.getSouthWest().lng());
        setE(bounds.getNorthEast().lng());
      }
    }, 200);
    return () => {
      clearTimeout(timeoutReference);
    };
  }, [map.mapObject]);

  //run query with the bounds
  useEffect(() => {
    if (n && s && e && w) {
      quoteQuery.get(
        quoteManualPath({
          consumption: consumption,
          kwhMode: kwhMode,
          lat: lat,
          lng: lng,
          north: n,
          south: s,
          east: e,
          west: w,
        })
      );
    }
  }, [n, s, w, e]);

  //handle query results
  useEffect(() => {
    if (quoteQuery.data && !quoteQuery.error && n && s && e && w) {
      const numberOfPanels = quoteQuery.data.x * quoteQuery.data.y + quoteQuery.data.extraPanels;

      monthlyKwhQuery.get(
        monthlyKwhPath({
          lat: lat,
          lng: lng,
          panels: numberOfPanels,
        })
      );

      waveQuery.get(
        wavePath({
          position: {
            latitude: lat,
            longitude: lng,
            north: n,
            south: s,
            east: e,
            west: w,
          },
        })
      );
      setWaveDataRenewed(true);

      const coords: Array<google.maps.LatLngLiteral[]> = [];
      if (quoteQuery.data.y > 0 && quoteQuery.data.x > 0) {
        const startPoint = computeDiagonalPoint(
          { lat: lat, lng: lng },
          ((quoteQuery.data.y + 2) * tileSize) / 2,
          ((quoteQuery.data.x + 2) * tileSize) / 2,
          180 + rot
        );
        if (quoteQuery.data.x * quoteQuery.data.y < 1000) {
          for (let y = 1; coords.length < numberOfPanels; y++) {
            for (let x = 1; x <= quoteQuery.data.x && coords.length < numberOfPanels; x++) {
              const coord = usePanelCoordinates(startPoint, rot, x, y);
              coord && coords.push(coord);
            }
          }
        } else {
          coords.push(
            computePolygonPathsDirect(
              { lat: lat, lng: lng },
              { width: tileSize * quoteQuery.data.x, height: tileSize * quoteQuery.data.y },
              rot
            )
          );
        }
      }
      if (map.mapObject) {
        panels.forEach((panel) => panel.setMap(null));
        const mapObject = map.mapObject;
        setPanels(
          coords.map((coord) => {
            return new google.maps.Polygon({
              strokeColor: '#ffe775',
              strokeOpacity: 1,
              strokeWeight: 1,
              fillColor: '#ffe775',
              fillOpacity: 0.8,
              map: mapObject,
              paths: coord,
              draggable: false,
            });
          })
        );
      }
      setX(quoteQuery.data.x);
      setY(quoteQuery.data.y);
      setExtraPanels(quoteQuery.data.extraPanels);
    }
  }, [quoteQuery.data]);

  const environmentDisabled =
    waveQuery.data == undefined ||
    waveQuery.error != undefined ||
    waveQuery.loading ||
    quoteQuery.loading;

  useEffect(() => {
    if (!environmentDisabled && waveQuery.data && waveDataRenewed) {
      if (
        Math.abs(waveQuery.data.lat - lat) > 0.0001 ||
        Math.abs(waveQuery.data.lng - lng) > 0.0001
      ) {
        map.waveMarker?.setVisible(true);
        map.waveMarker?.setPosition({ lat: waveQuery.data.lat, lng: waveQuery.data.lng });
      }
    } else {
      map.waveMarker?.setVisible(false);
    }
  }, [environmentDisabled]);

  const numberOfPanels = x * y + extraPanels;

  return (
    <>
      <div
        className={
          'flex flex-col items-center justify-between w-full h-1/2 wh0:h-screen wh0:mt-0 wh0:pb-28 wh1:pb-32 flex-grow-no-shrink' +
          ' ' +
          ((isChrome && (isDesktop || isIOS) && 'pb-0') || 'pb-16')
        }
      >
        <BackButton
          onClick={() => {
            const searchParams = new URLSearchParams();
            if (place) searchParams.append('place', place);
            searchParams.append('consumption', consumption.toString());
            searchParams.append('zoom', zoom.toString());
            searchParams.append('lat', lat.toFixed(5));
            searchParams.append('lng', lng.toFixed(5));
            searchParams.append('rot', rot.toString());
            searchParams.append('kwhMode', kwhMode.toString());
            searchParams.append('page', '1');
            navigate('/?' + searchParams.toString());
          }}
          label="Back to placement"
        />

        {page == 2 && (
          <ReactTooltip
            id="mapClick"
            delayHide={5000}
            className="tooltip"
            effect="solid"
            place="top"
            overridePosition={({ top, left }) => {
              let top2 = top - 200;
              if (isIOS) {
                top2 = top; // for some reason the popup does not appear on top of the map on ios
              }
              return { top: top2, left: left };
            }}
          >
            <div className="w-40 text-center">
              <span className="text-base leading-3 text-white">
                Go back if you would like to adjust placement{' '}
              </span>
            </div>
          </ReactTooltip>
        )}

        {quoteQuery.loading && (
          <>
            <div className="flex flex-col max-w-screen-lg pb-12 mx-auto">
              <div className="mx-auto text-xl">Calculating quote</div>
              <div className="mx-auto mt-4">
                <img src="/img/spinner.svg" alt="Loading" />
              </div>
            </div>
            <div className="h-1">{/*To center the spinner*/}</div>
          </>
        )}

        {quoteQuery.error && (
          <>
            <div className="mx-10 text-xl text-center">
              There has been an error with calculating the quote. Please try again later.
            </div>
            <div className="h-1">{/*lazy spacing*/}</div>
          </>
        )}

        {!quoteQuery.loading && quoteQuery.data && !quoteQuery.error && (
          <>
            <div className="flex flex-col items-center w-full">
              <div className="flex flex-row items-center justify-center w-full wh0:flex-col">
                <CircledNumber number={2} />

                <h2 className="mt-0.5 leading-tight wh0:ml-0 ml-1 wh1:leading-normal text-base wh1:text-lg wh3:text-2xl wh7:text-6xl wh3:mt-2 wh5:text-4xl ">
                  Summary
                </h2>
              </div>

              <div className="mt-0.5 text-xs wh1:text-sm wh3:text-base wh5:text-xl wh7:text-3xl wh7:mt-3">
                Location:
                {' ' + (lat >= 0 ? lat : -lat).toFixed(4)}
                {lat >= 0 ? 'N ' : 'S '}
                {(lng >= 0 ? lng : -lng).toFixed(4)}
                {lng >= 0 ? 'E' : 'W'}{' '}
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center justify-around w-full text-center">
              <ResultField
                data={
                  <>
                    {prettyNumber(Math.round(numberOfPanels * tileSize ** 2))} m<sup>2</sup>
                  </>
                }
                label="Area of installation"
                hoverText="Anchoring and mooring will extend beyond the quoted area."
                tooltipID="1"
              />
              <ResultField data={prettyNumber(numberOfPanels)} label="Number of panels" />
              <ResultField
                data={prettyNumber(quoteQuery.data?.kwhPerYear ?? 0) + ' kWh/year'}
                label="Energy production"
                hoverText="Energy production is described on the monthly energy production chart in the environmental data tab below."
                tooltipID="2"
              />
              <ResultField
                data={prettyNumber(quoteQuery.data?.co2Reduction ?? 0) + ' kg/year'}
                label={
                  <>
                    Reduced CO<sub>2</sub> emission
                  </>
                }
                hoverText={
                  <>
                    The estimated reduced amount of CO<sub>2</sub> is calculated from the CO
                    <sub>2</sub> footprint of the local energy mix.
                  </>
                }
                tooltipID="3"
              />
            </div>

            <div className="flex flex-col items-center w-full">
              <div className="flex flex-row items-center justify-center w-full wh0:flex-col">
                <h2 className="pr-2 text-sm wh0:text-base wh3:text-lg wh5:text-2xl wh7:text-4xl wh0:pr-0">
                  Estimated price
                </h2>
                <span className="mt-0 text-xs wh0:text-sm wh3:text-lg wh5:text-xl wh7:text-3xl wh7:mt-2">
                  {prettyNumber(quoteQuery.data?.installationCost ?? 0)} EUR
                </span>
              </div>

              <Modal
                title="Price"
                text={
                  'The price given in the quote is not final. The project might require legal, environmental, and technical pre-feasibility work before initiated. ' +
                  'The price includes delivery of the components to the installation site, including launch and connection to the mooring system. ' +
                  'Anchoring and mooring is specified by Sunlit Sea, but not included in the quote. The project may be applicable for financial support through governmental support.'
                }
                trigger={
                  <button
                    type="button"
                    className="w-64 px-2 mt-1 leading-snug text-gray-600 underline wh0:mt-2 wh3:w-96 text-xs2 wh5:text-base wh1:text-xs wh7:w-120 wh7:text-lg wh1:leading-4 wh3:text-sm wh1:w-80 hover:cursor-pointer focus:outline-none active:border-transparent focus:ring-1 focus:ring-gray-400"
                  >
                    This price is not final. Manual pre-feasibility work is necessary to give a
                    detailed and final quote.
                  </button>
                }
              />
            </div>

            <div className="flex flex-col items-center w-full">
              {quoteQuery.data?.coverage < 0.95 && quoteQuery.data?.coverage > 0.1 && (
                <WaterCoverage
                  text="The installation seems to be partially on land. A manual inspection of the site might be needed to determine whether this is a suitable location."
                  className="text-alert"
                  coverage={quoteQuery.data.coverage}
                />
              )}
              {quoteQuery.data?.coverage <= 0.1 && (
                <WaterCoverage
                  text="The installation seems to be on land. It might not be possible to place an installation here, or our datasets are missing a water body for this location."
                  className="text-alert"
                  coverage={quoteQuery.data.coverage}
                />
              )}
            </div>

            <div className="flex flex-col items-center w-full mt-1 text-xs2 wh1:text-xs wh3:text-sm wh5:text-lg wh7:text-xl">
              <div className="flex flex-row items-end w-full">
                <div className="w-full max-w-full px-2 mx-auto text-center wh5:px-6">
                  {(monthlyKwhQuery.loading && (
                    <div className="flex flex-row place-content-center">
                      Loading environmental data
                      <img
                        src="/img/spinner.svg"
                        alt="Loading"
                        className="w-3 h-3 ml-1 wh1:h-5 wh1:w-5"
                      />
                    </div>
                  )) ||
                    'Environmental data is available'}
                </div>

                <span className="w-full px-2 text-center">
                  Get in touch with us to get a detailed quote
                </span>
              </div>

              <div className="flex flex-row items-center w-full pb-1 wh1:pb-2">
                <button
                  className={`px-6 py-1.5 wh1:py-2 border-transparent rounded-lg wh7:mt-2 w-20 wh1:w-28 wh5:w-36  mt-1 mx-auto focus:outline-none active:border-transparent focus:ring-1 focus:ring-secondary-700 ${
                    !monthlyKwhQuery.loading
                      ? 'bg-secondary-600 hover:bg-secondary-800 focus:ring-1 focus:ring-secondary-700'
                      : 'bg-gray-400 cursor-default'
                  }`}
                  type="button"
                  disabled={monthlyKwhQuery.loading}
                  onClick={() => {
                    const searchParams = new URLSearchParams(loc.search);
                    searchParams.delete('page');
                    searchParams.append('page', '3');
                    navigate('/?' + searchParams.toString());
                  }}
                >
                  <span className="text-white ">Show</span>
                </button>
                <button
                  className={`px-6 py-1.5 wh1:py-2 border-transparent rounded-lg mt-1 wh7:mt-2 w-20 wh1:w-28 wh5:w-36 mx-auto focus:outline-none active:border-transparent bg-secondary-600 hover:bg-secondary-800 focus:ring-1 focus:ring-secondary-700`}
                  type="button"
                  onClick={() => {
                    const searchParams = new URLSearchParams(loc.search);
                    searchParams.delete('page');
                    searchParams.append('page', '4');
                    navigate('/?' + searchParams.toString());
                  }}
                >
                  <span className="text-white g">Contact</span>
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Result;
