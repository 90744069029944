import React from 'react';
import { Tooltip } from './Tooltip';

type Props = {
  text: string | React.ReactNode;
  className: string | React.ReactNode;
  coverage: number;
};

export const WaterCoverage: React.FC<Props> = ({ text, className, coverage }) => {
  return (
    <div
      className={
        'w-80 wh-1:w-96 wh0:w-64 mt-0.5 leading-none text-center text-xs3 wh-1:text-xs2 wh0:mt-2  wh1:text-xs wh1:w-80 wh3:w-96 wh5:w-120 wh7:w-156 wh3:text-sm wh5:text-base wh7:text-lg' +
        ' ' +
        className
      }
    >
      {text}
      <Tooltip
        tooltipID="coverage"
        message={'Installation water coverage: ' + (coverage * 100).toFixed(0) + '%'}
        svgSize="wh7:w-4 wh7:h-4 wh1:w-3 wh1:h-3 w-2 h-2 "
      />
    </div>
  );
};
