import { stringify } from 'query-string';

export type MonthlyKwhInput = {
  lat: number;
  lng: number;
  panels: number;
};

export type MonthlyKwhResponse = {
  monthlyKwh: number[];
};

export const monthlyKwhPath = (input: MonthlyKwhInput): string =>
  `/api/monthlykwh?${stringify({
    lat: input.lat,
    lng: input.lng,
    panels: input.panels,
  })}`;
