import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
import HighchartMore from 'highcharts/highcharts-more';
import Highcharts from 'highcharts';

HighchartMore(Highcharts);

const windColors = [
  '#fff1a3', //100
  '#ffd46f', //300
  '#ffaf63', //500
  '#ff8a57', //700
  '#ff6242', //900
];

const waveColors5 = ['#e1e7fa', '#b4bce8', '#737cc7', '#4b5494', '#2f354f'];
const waveColors6 = ['#ebefff', '#e1e7fa', '#b4bce8', '#737cc7', '#4b5494', '#2f354f'];

export class PolarChart extends Component {
  constructor(freq: number[][], intervals: number[], type: string) {
    super({});

    let intervalStrings: string[] = [];
    for (let i = 0; i < intervals.length; i++) {
      intervalStrings.push(intervals[i].toFixed(1));
    }

    let colors = windColors;
    let unit = 'm/s';
    if (type == 'wave') {
      colors = waveColors5;
      if (freq.length == 6) colors = waveColors6;
      unit = 'm';
      intervalStrings = [];
      for (let i = 0; i < intervals.length; i++) {
        intervalStrings.push(intervals[i].toFixed(2));
      }
    }

    const series: {
      type: string;
      name: string;
      data: number[];
      color: string;
      visible: boolean;
    }[] = [];

    series.push({
      type: 'column',
      name: `(0 - ${intervalStrings[0]}] ${unit}`,
      color: colors[0],
      data: freq[0],
      visible: type != 'wave',
    });
    for (let i = 1; i < intervals.length; i++) {
      series.push({
        type: 'column',
        name: `(${intervalStrings[i - 1]} - ${intervalStrings[i]}] ${unit}`,
        color: colors[i],
        data: freq[i],
        visible: true,
      });
    }
    series.push({
      type: 'column',
      name: `${intervalStrings[intervalStrings.length - 1]}+ ${unit}`,
      color: colors[freq.length - 1],
      data: freq[freq.length - 1],
      visible: true,
    });

    this.state = {
      // To avoid unnecessary update keep all options in the state.
      chartOptions: {
        title: {
          text: undefined,
        },
        chart: {
          polar: true,
          backgroundColor: 'rgba(255,255,255,0)',
          height: '60%',
          spacing: [0, 0, 0, 30],
          marginTop: 0,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: [
            '0',
            '30',
            '60',
            '90',
            '120',
            '150',
            '180',
            '210',
            '240',
            '270',
            '300',
            '330',
          ],
        },
        series: series,
        yAxis: {
          min: 0,
          endOnTick: false,
          showLastLabel: true,
          labels: {
            formatter: function () {
              //eslint-disable-next-line
              //@ts-ignore
              const value = this.value;
              if (value == 0) {
                return '';
              }
              return value + '%';
            },
            style: {
              color: '#222222',
              fontSize: '13px',
              fontWeight: '900',
            },
            x: 13,
            y: 0,
          },
          reversedStacks: false,
        },
        legend: {
          align: 'right',
          verticalAlign: 'middle',
          layout: 'vertical',
          margin: 20,
        },
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y}</b><br/>`,
          valueSuffix: '%',
          valueDecimals: 2,
          followPointer: true,
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            shadow: false,
            groupPadding: 0,
            pointPlacement: 'on',
          },
        },
      },
      hoverData: null,
    };
  }

  render(): JSX.Element {
    //eslint-disable-next-line
    //@ts-ignore
    const { chartOptions } = this.state;

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
  }
}
