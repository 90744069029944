import React from 'react';

import ReactTooltip from 'react-tooltip';
import QuestionMark from './QuestionMark';

type Props = {
  message: string | React.ReactNode;
  tooltipID: string;
  svgSize?: string | null;
};

export const Tooltip: React.FC<Props> = ({ message, tooltipID, svgSize = null }) => {
  return (
    <span data-tip data-for={tooltipID} className="mt-0.5 wh1:mt-1 inline-block ml-0.5">
      <QuestionMark className={svgSize ?? 'wh5:h-5 wh5:w-5'} />
      <ReactTooltip id={tooltipID} className="tooltip">
        <div className="w-48 text-xs wh5:leading-5 wh2:text-sm wh5:text-base font-arial">
          {message}
        </div>
      </ReactTooltip>
    </span>
  );
};
