import React from 'react';

interface Props {
  className: string;
}

const VSymbol: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.69407 17.4262L15.9564 -1.33038e-07L19 1.33038e-07L12.5313 18C11.5209 17.9861 10.5104 17.9721 9.5 17.9582"
        fill="#101010"
      />
      <path
        d="M9.30592 17.4262L3.04356 1.61959e-07L-7.62939e-06 -1.04118e-07L6.46869 18C7.47912 17.9861 8.48956 17.9721 9.5 17.9582"
        fill="#101010"
      />
    </svg>
  );
};

export default VSymbol;
