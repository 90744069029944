import { stringify } from 'query-string';

export type RequestWaveInput = {
  position: {
    latitude: number;
    longitude: number;
    north: number;
    south: number;
    east: number;
    west: number;
  };
};

export type WaveQueryResponse = {
  windFreq: number[][];
  waveFreq: number[][];
  windIntervals: number[];
  waveIntervals: number[];
  returnPeriodWinds: number[];
  returnPeriodWaves: number[];
  lat: number;
  lng: number;
};

export const wavePath = (input: RequestWaveInput): string =>
  `/api/wave?${stringify({
    lat: input.position.latitude,
    lng: input.position.longitude,
    north: input.position.north,
    south: input.position.south,
    east: input.position.east,
    west: input.position.west,
  })}`;
