import { useGoogleMapsReady } from './google-maps';

let _autocompleteService: google.maps.places.AutocompleteService | null = null;

export const useAutocompleteService: () => google.maps.places.AutocompleteService | null = () => {
  const mapsReady = useGoogleMapsReady();
  if (_autocompleteService !== null) {
    return _autocompleteService;
  }
  if (!mapsReady) {
    return null;
  }
  _autocompleteService = new google.maps.places.AutocompleteService();
  return _autocompleteService;
};
