import React, { useEffect, useState } from 'react';
import { isChrome, isDesktop, isIOS } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGeocoderService } from '../../api/google-maps/useGeocoderService';
import { MapWithMarkers } from '../../api/google-maps/useMap';
import useDebounce from '../../hooks/useDebounce';
import { computePolygonPaths } from '../../utils/geoOps';
import { Location } from '../../utils/LocationType';
import { MatrixSize } from '../../utils/MatrixSize';
import { BackButton } from '../atoms/BackButton';
import { CircledNumber } from '../atoms/CircledNumber';

const minCoverage = 0.0001;
// eslint-disable-next-line
const maxCoverage = 0.05;

type Props = {
  map: MapWithMarkers;
  center: { lat: number; lng: number };
  mapBoundsSize: number;
  zoom: number;
};

const Placement: React.FC<Props> = ({ map, center, mapBoundsSize, zoom }) => {
  const loc = useLocation();
  const navigate = useNavigate();

  const [, setIsZoomCorrect] = useState(false);

  const [rotation, setRotation] = useState(Number(new URLSearchParams(loc.search).get('rot')));

  const consumption = Number(new URLSearchParams(loc.search).get('consumption'));
  const kwhMode = new URLSearchParams(loc.search).get('kwhMode') === 'true';
  const page = Number(new URLSearchParams(loc.search).get('page'));

  const [matrixSize] = useState(new MatrixSize(consumption, kwhMode, 0));

  const geocodedLocation = useGeocodedLocationFromUrl();

  initMap(page, map, geocodedLocation, matrixSize, rotation);

  updateUrl(page, kwhMode, center, navigate, rotation, zoom);

  updateMarkers(page, map, matrixSize, center, rotation);

  updateMarkerVisibility(page, mapBoundsSize, matrixSize, setIsZoomCorrect, map);

  useEffect(() => {
    map.polygon?.setPaths(computePolygonPaths(center, matrixSize, rotation));
  }, [rotation]);

  return (
    <>
      <div
        className={
          'flex flex-col items-center justify-between w-full h-1/2 wh0:h-screen wh0:pb-32 flex-grow-no-shrink' +
          ' ' +
          ((isChrome && (isDesktop || isIOS) && 'pb-0') || 'pb-16')
        }
      >
        <BackButton
          onClick={() => {
            const place = new URLSearchParams(loc.search).get('place');
            const searchParams = new URLSearchParams();
            if (place) {
              searchParams.append('place', place);
            }
            searchParams.append('consumption', consumption.toString());
            searchParams.append('kwhMode', kwhMode.toString());
            searchParams.append('page', '0');
            navigate('/?' + searchParams.toString());
          }}
          label="Back to start"
        />
        <div className="flex flex-col items-center w-full">
          <div className="flex flex-row items-center justify-center w-full wh0:flex-col">
            <CircledNumber number={1} />
            {geocodedLocation && (
              <h2 className="mx-1 text-lg leading-3 text-center wh0:mx-4 wh-1:mt-1 wh-1:text-xl wh0:mt-2 wh5:text-4xl wh5:mt-4">
                {geocodedLocation?.name}
              </h2>
            )}
          </div>

          <div className="mt-0.5 wh0:mt-1 text-sm wh0:text-base wh5:text-2xl wh5:mt-2">
            {(center.lat >= 0 ? center.lat : -center.lat).toFixed(4)}
            {center.lat >= 0 ? 'N ' : 'S '}
            {(center.lng >= 0 ? center.lng : -center.lng).toFixed(4)}
            {center.lng >= 0 ? 'E' : 'W'}{' '}
          </div>
        </div>

        <div className="flex flex-col items-center w-full text-xs2 wh-1:text-xs">
          <div className="flex flex-row items-center mx-auto wh0:w-full wh0:flex-col">
            <div className="w-32 wh0:w-full mx-1 mt-0.5 text-center wh0:mt-2  wh0:text-base wh5:text-2xl">
              Do you want to adjust the location?
            </div>
            <img
              className="w-20 mx-1 mt-2 pointer-events-none wh-1:w-24 wh5:w-48"
              src="img/ClickAndDrag.png"
            />
            <div className="w-32 mx-1 text-center text-gray-600 wh0:w-48 wh0:text-sm wh5:text-lg">
              Click and drag the installation on the map
            </div>
          </div>
          <div className="mt-1 text-center text-gray-600 wh0:mt-2 wh0:text-sm w-96 wh5:text-lg">
            Other geometries are available on request
          </div>
        </div>

        <div className="flex flex-row w-3/4 px-4 pt-1 wh0:flex-col wh5:pt-4 wh5:w-1/2">
          <span className="pr-1 -mt-1 text-base wh5:text-xl wh0:mt-0">Rotation</span>
          <div className="w-full">
            <input
              type="range"
              min="0"
              max="180"
              value={rotation}
              onInput={(event) => {
                setRotation(Number(event.currentTarget.value));
              }}
              onTouchMove={(event) => {
                if (event.touches.item(0)) {
                  const sliderPercentage =
                    (event.touches.item(0).pageX - event.currentTarget.offsetLeft) /
                    event.currentTarget.clientWidth;
                  setRotation(Math.max(Math.min(sliderPercentage, 1), 0) * 180);
                }
              }}
              step="15"
              className="w-full focus:outline-none focus:ring-1 focus:ring-gray-400"
            />
          </div>
        </div>

        <div className="flex flex-col items-center w-full pb-1 wh1:pb-2">
          <button
            className={`flex flex-row py-1 wh-1:py-2 wh0:mt-2 wh5:w-96 border-transparent rounded-lg wh1:w-80 w-64 mx-auto focus:outline-none active:border-transparent  focus:ring-1 focus:ring-gray-400 `}
            onClick={() => {
              forceZoom(map, matrixSize, setIsZoomCorrect);
            }}
          >
            <div className="ml-2 leading-tight text-gray-800 underline text-xs2 wh-1:text-xs wh0:text-sm wh1:text-base wh5:text-lg">
              Zoom until the installation appears on the map, or click here to force the correct
              zoom.
            </div>
          </button>

          <button
            className="px-6 py-1 wh0:py-2 mt-0.5 wh0:mt-2 border-transparent rounded-lg w-52 mx-auto focus:outline-none active:border-transparent  focus:ring-1 focus:ring-secondary-700 bg-secondary-600 hover:bg-secondary-800"
            onClick={() => {
              forceZoom(map, matrixSize, setIsZoomCorrect);

              const searchParams = new URLSearchParams();
              searchParams.append('consumption', consumption.toString());
              searchParams.append('kwhMode', kwhMode.toString());
              searchParams.append('place', new URLSearchParams(loc.search).get('place') ?? '0');
              searchParams.append('rot', rotation.toString());
              if (map.mapObject) {
                searchParams.append('zoom', map.mapObject.getZoom().toString());
                const bounds = map.mapObject.getBounds();
                if (bounds) {
                  searchParams.append('lat', bounds.getCenter().lat().toFixed(5));
                  searchParams.append('lng', bounds.getCenter().lng().toFixed(5));
                }
              }
              searchParams.append('page', '2');
              navigate('/?' + searchParams.toString());
            }}
          >
            <span className="text-base leading-tight text-white wh0:text-lg wh5:text-xl">
              Calculate quote
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Placement;

function forceZoom(
  map: MapWithMarkers,
  matrixSize: MatrixSize,
  setIsZoomCorrect: React.Dispatch<React.SetStateAction<boolean>>
) {
  if (map.domRef.current && map.mapObject) {
    const mapPixels = Math.min(map.domRef.current.clientHeight, map.domRef.current.clientWidth);
    const desiredCoverage = 0.001;
    const mapMetersMax = Math.min(matrixSize.width, matrixSize.height) / Math.sqrt(desiredCoverage);
    const metersPerPixel = mapMetersMax / mapPixels;
    //formula from https://gis.stackexchange.com/a/127949
    let zoom = Math.log2(
      (156543.03392 * Math.cos((map.mapObject.getCenter().lat() * Math.PI) / 180.0)) /
      metersPerPixel
    );
    zoom = Math.round(zoom);
    // add one because sometimes it zooms out too much
    zoom += 1;
    map.mapObject.setZoom(zoom);
    setIsZoomCorrect(true);
  }
}

function updateMarkerVisibility(
  page: number,
  mapBoundsSize: number,
  matrixSize: MatrixSize,
  setIsZoomCorrect: React.Dispatch<React.SetStateAction<boolean>>,
  map: MapWithMarkers
) {
  useEffect(() => {
    if (page == 1 && mapBoundsSize > 0) {
      const coverage = matrixSize.area / mapBoundsSize;
      if (coverage > minCoverage) {
        //if area is under 20 then it's impossible to zoom close enough to satisfy minCoverage
        map.marker?.setVisible(false);
        map.polygon?.setVisible(true);
        setIsZoomCorrect(true);
      } else {
        map.marker?.setVisible(true);
        map.polygon?.setVisible(false);
        setIsZoomCorrect(false);
      }
    }
  }, [mapBoundsSize, page]);
}

function initMap(
  page: number,
  map: MapWithMarkers,
  geocodedLocation: Location | null,
  matrixSize: MatrixSize,
  rotation: number
) {
  const loc = useLocation();
  useEffect(() => {
    const latUrl = Number(new URLSearchParams(loc.search).get('lat'));
    const lngUrl = Number(new URLSearchParams(loc.search).get('lng'));
    const zoomUrl = Number(new URLSearchParams(loc.search).get('zoom'));
    const place = new URLSearchParams(loc.search).get('place');
    const mapObject = map.mapObject;
    if (page == 1 && mapObject) {
      let initCenter = { lat: 0, lng: 0 };
      if (!(latUrl == 0 && lngUrl == 0) && zoomUrl != 0) {
        initCenter = { lat: latUrl, lng: lngUrl };
        mapObject.setCenter(initCenter);
        mapObject.setZoom(zoomUrl);
      } else if (!place) {
        mapObject.setCenter(initCenter);
        mapObject.setZoom(2);
      } else if (geocodedLocation) {
        initCenter = { lat: geocodedLocation.latitude, lng: geocodedLocation.longitude };
        mapObject.fitBounds(
          new google.maps.LatLngBounds(
            { lat: geocodedLocation.south, lng: geocodedLocation.west },
            { lat: geocodedLocation.north, lng: geocodedLocation.east }
          )
        );
      }
      matrixSize.update(initCenter.lat);
      map.marker?.setPosition(initCenter);
      map.polygon?.setPath(computePolygonPaths(initCenter, matrixSize, rotation));
    }
  }, [map.mapObject, geocodedLocation, page]);
}

function updateMarkers(
  page: number,
  map: MapWithMarkers,
  matrixSize: MatrixSize,
  center: { lat: number; lng: number },
  rotation: number
) {
  const [lastCenter, setLastCenter] = useState(center);
  if (map.mapObject && page == 1 && lastCenter != center) {
    matrixSize.update(center.lat);
    if (map.polygon) {
      map.polygon.setPaths(computePolygonPaths(center, matrixSize, rotation));
    }
    map.marker?.setPosition({
      lat: center.lat,
      lng: center.lng,
    });
    setLastCenter(center);
  }
}

function updateUrl(
  page: number,
  kwhMode: boolean,
  center: { lat: number; lng: number },
  navigate,
  rotation: number,
  zoom: number
) {
  const rotationDebounced = useDebounce<number>(rotation, 500);
  const zoomDebounced = useDebounce<number | undefined>(zoom, 500);
  const centerDebouncedMore = useDebounce<{ lat; lng }>(center, 500);

  const loc = useLocation();
  useEffect(() => {
    if (page == 1) {
      const lat = new URLSearchParams(loc.search).get('lat');
      const lng = new URLSearchParams(loc.search).get('lng');
      const zoomUrl = new URLSearchParams(loc.search).get('zoom');
      const consumption = new URLSearchParams(loc.search).get('consumption');
      const place = new URLSearchParams(loc.search).get('place');

      const searchParams = new URLSearchParams();
      if (place) searchParams.append('place', place);
      searchParams.append('rot', rotationDebounced.toString());
      if (consumption) searchParams.append('consumption', consumption);
      searchParams.append('kwhMode', kwhMode.toString());
      if (!(center.lat == 0 && center.lng == 0)) {
        searchParams.append('lat', center.lat.toFixed(5));
        searchParams.append('lng', center.lng.toFixed(5));
      } else if (lat && lng) {
        searchParams.append('lat', lat);
        searchParams.append('lng', lng);
      }
      if (zoomDebounced && zoomDebounced != 0)
        searchParams.append('zoom', zoomDebounced.toString());
      else if (zoomUrl) searchParams.append('zoom', zoomUrl);
      searchParams.append('page', '1');
      navigate('/?' + searchParams.toString(), { replace: true });
    }
  }, [rotationDebounced, centerDebouncedMore, zoomDebounced]);
}

function useGeocodedLocationFromUrl(): Location | null {
  const geocoder = useGeocoderService();
  const [geocodedLocation, setGeocodedLocation] = useState<Location | null>(null);
  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const place = new URLSearchParams(useLocation().search).get('place');
  if (place && !geocodedLocation && !isLocationLoading) {
    //must have a geocoder check here becuse the first call to "geocoder?" initializes it
    if (geocoder && !isLocationLoading) {
      setIsLocationLoading(true);
    }
    geocoder?.geocode({ placeId: place }, (results, status) => {
      if (status === 'OK' && results[0]) {
        let name = results[0].formatted_address;
        let aal1 = '';
        let aal2 = '';
        let locality = '';
        let post = '';
        for (const a of results[0].address_components) {
          if (a.types.includes('administrative_area_level_1')) {
            aal1 = a.long_name;
          } else if (a.types.includes('administrative_area_level_2')) {
            aal2 = a.long_name;
          } else if (a.types.includes('locality')) {
            locality = a.long_name;
          } else if (a.types.includes('postal_town')) {
            post = a.long_name;
          }
        }

        if (locality != '') {
          name = locality;
        } else if (post != '') {
          name = post;
        } else if (aal1 != '') {
          name = aal1;
        } else if (aal2 != '') {
          name = aal2;
        }

        setGeocodedLocation({
          latitude: results[0].geometry.location.lat(),
          longitude: results[0].geometry.location.lng(),
          name: name,
          north: results[0].geometry.viewport.getNorthEast().lat(),
          east: results[0].geometry.viewport.getNorthEast().lng(),
          south: results[0].geometry.viewport.getSouthWest().lat(),
          west: results[0].geometry.viewport.getSouthWest().lng(),
        });
      }
    });
  }
  return geocodedLocation;
}
