import React, { useEffect, useState } from 'react';

import Helmet from 'react-helmet';

import { useLocation, useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import { QuoteManualResponse } from '../../api/client/quoteQuery';
import { WaveQueryResponse } from '../../api/client/waveQuery';
import { mapOptionsDisabled, MapWithMarkers, useMap } from '../../api/google-maps/useMap';
import { calculateBoundsArea } from '../../utils/geoOps';
import Contact from './Contact';
import Environment from './Environment';
import Inquiry from './Inquiry';
import Placement from './Placement';
import Result from './Result';
import { mapOptions } from './../../api/google-maps/useMap';
import { Element, scroller } from 'react-scroll';
import useDebounce from './../../hooks/useDebounce';
import FocusLock from 'react-focus-lock';
import { MissingImagery } from '../atoms/MissingImagery';
import { MonthlyKwhResponse } from '../../api/client/monthlyKwhQuery';
import { isDesktop } from 'react-device-detect';
import ReactGA from 'react-ga';
import prop from '../../utils/Prop';

type ScrollOptions = {
  duration: number;
  delay: number;
  smooth: string | boolean;
  containerId: null | string;
  ignoreCancelEvents: boolean;
};

const scrollTo = (pageId, setLastPage, page, map, sidePanel, forcedScroll, instant) => {
  const timeoutReference = setTimeout(() => {
    const options: ScrollOptions = {
      duration: 300,
      delay: 0,
      smooth: 'easeInOutQuad',
      containerId: null,
      ignoreCancelEvents: true,
    };
    if (instant) {
      options.duration = 0;
      options.smooth = false;
    }
    if (sidePanel) {
      options.containerId = 'side-panel';
    }
    scroller.scrollTo(pageId, options);
    forcedScroll.value = true;
  }, 100);

  if (!sidePanel) {
    const timeoutReference2 = setTimeout(() => {
      setLastPage(page); // MAKES THE PAGES BELOW DISAPPEAR, SO THAT THEY WILL INITIALIZE CORRECTLY AGAIN
      //use page url so that navigating back and forth quicker than timeout doesn't result in a reset map
      if ((new URLSearchParams(location.search).get('page') ?? 0) == 0 && map.mapObject) {
        //RESETS THE MAP SO THAT IT WILL INITIALIZE CORRECTLY AGAIN
        map.mapObject.setCenter({ lat: 0, lng: 0 });
        map.mapObject.setZoom(0);
      }
      forcedScroll.value = false;
    }, 800);
    return () => {
      clearTimeout(timeoutReference);
      clearTimeout(timeoutReference2);
    };
  }
};

const Main: React.FC = () => {
  const loc = useLocation();
  const navigate = useNavigate();

  const pageArr = useState(Number(new URLSearchParams(loc.search).get('page') ?? 0));
  let page = pageArr[0];
  const setPage = pageArr[1];

  const [lastPage, setLastPage] = useState(page); //used for having the scroll effect when going back

  const [showUrlPopupState, setShowUrlPopup] = useState(false);
  let showUrlPopup = showUrlPopupState;
  //check url parameters on navigation
  useEffect(() => {
    if (page > 0) {
      const params = new URLSearchParams(loc.search);
      if (
        ((!Number(params.get('consumption')) ||
          !(params.get('kwhMode') === 'true' || params.get('kwhMode') === 'false')) &&
          page > 0) ||
        ((!Number(params.get('lat')) ||
          !Number(params.get('lng')) ||
          !Number(params.get('zoom'))) &&
          page > 1)
      ) {
        setTimeout(() => {
          setShowUrlPopup(false);
        }, 10000);
        showUrlPopup = true;
        setShowUrlPopup(true);
        page = 0;
        setPage(0);
        navigate('/');
      }
    }
  }, [loc]);

  useEffect(() => {
    setLastPage(page);
    const realPage = Number(new URLSearchParams(loc.search).get('page') ?? 0)
    if (prop.ga) {
      console.log('pageview(/page/' + realPage + ')');
      ReactGA.pageview('/page/' + realPage);
    }
    setPage(realPage);
  }, [new URLSearchParams(loc.search).get('page')]);

  const map = useMap();
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [boundsSize, setBoundsSize] = useState(0);
  const [zoom, setZoom] = useState(0);
  const [panels, setPanels] = useState<google.maps.Polygon[]>([]);
  const waveQuery = useFetch<WaveQueryResponse>('', {
    headers: { 'Content-Type': 'application/json' },
  });
  const quoteQuery = useFetch<QuoteManualResponse>('', {
    headers: { 'Content-Type': 'application/json' },
  });
  const monthlyKwhQuery = useFetch<MonthlyKwhResponse>('', {
    headers: { 'Content-Type': 'application/json' },
  });

  const [windowSize, setWindowSize] = useState({ w: window.innerWidth, h: window.innerHeight }); //used to detect window resize
  handleDisabledScrolling(page, map, setLastPage, lastPage, panels, windowSize, setWindowSize);

  onMapInit(map, setCenter, setBoundsSize, setZoom, page);

  return (
    <>
      <Helmet>
        <title>Smart Quoter</title>
      </Helmet>

      <Element name="page0">
        {showUrlPopup && (
          <div className="absolute z-50 w-full pointer-events-none">
            <div className="p-1 mx-auto mt-8 text-center rounded-lg wh0:p-2 w-60 wh0:w-48 bg-support-dark">
              <div className="text-xs leading-4 text-white wh0:text-sm ">
                ⚠ It seems that the link you have pasted was invalid. You have been redirected to
                the main page.
              </div>
            </div>
          </div>
        )}
        <FocusLock disabled={page != 0} autoFocus={false}>
          <Inquiry />
        </FocusLock>
      </Element>

      <Element
        className={
          'flex flex-col w-full wh0:flex-row whiteSecondaryGradient' +
          ' ' +
          ((!(page >= 1 || lastPage >= 1) && 'invisible h-0 overflow-hidden') ||
            'h-screen wh0:h-auto')
        }
      >
        <Element
          name="split-panel"
          id="split-panel"
          className="w-full pb-16 wh0:pb-20 wh0:-mt-20 h-1/2 wh0:w-7/12 wh0:h-screen wh1:pb-32 wh1:-mt-32"
        >
          <MissingImagery
            center={center}
            zoom={zoom}
            page={page}
            zoomService={map.maxZoomService}
          />

          <div
            ref={map.domRef}
            className="w-full h-full"
            data-tip
            data-for="mapClick"
            data-event="mousedown"
            data-event-off="mousedown"
          />
        </Element>
        <Element
          className="z-0 flex flex-col w-full -mt-16 overflow-hidden wh0:-mt-20 h-1/2 wh0:w-5/12 wh0:h-screen wh1:-mt-32"
          name="side-panel"
          id="side-panel"
        >
          {(page >= 1 || lastPage >= 1) && (
            <FocusLock disabled={page != 1} autoFocus={false}>
              <Element name="page1" className="w-full h-screen wh0:h-auto">
                <Placement map={map} center={center} mapBoundsSize={boundsSize} zoom={zoom} />
              </Element>
            </FocusLock>
          )}
          {(page >= 2 || lastPage >= 2) && (
            <FocusLock disabled={page != 2} autoFocus={false}>
              <Element name="page2" className="w-full h-screen wh0:h-auto">
                <Result
                  map={map}
                  panels={panels}
                  setPanels={setPanels}
                  waveQuery={waveQuery}
                  monthlyKwhQuery={monthlyKwhQuery}
                  quoteQuery={quoteQuery}
                />
              </Element>
            </FocusLock>
          )}
        </Element>
      </Element>
      {(page == 3 || lastPage == 3) && (
        <FocusLock disabled={page != 3} autoFocus={false}>
          <Element name="page3" className="w-full bg-secondary-50">
            <Environment
              map={map}
              waveQuery={waveQuery}
              monthlyKwhQuery={monthlyKwhQuery}
              hasSea={quoteQuery.data?.hasSea ?? false}
              hasLake={quoteQuery.data?.hasLake ?? false}
              windowSize={windowSize}
            />
          </Element>
        </FocusLock>
      )}
      {(page >= 4 || lastPage >= 4) && (
        <FocusLock disabled={page != 4} autoFocus={false}>
          <Element name="page4" className="relative z-10 w-full mx-auto bg-secondary-50">
            <Contact />
          </Element>
        </FocusLock>
      )}
    </>
  );
};

export default Main;

function onMapInit(
  map: MapWithMarkers,
  setCenter: React.Dispatch<React.SetStateAction<{ lat: number; lng: number }>>,
  setBoundsSize: React.Dispatch<React.SetStateAction<number>>,
  setZoom: React.Dispatch<React.SetStateAction<number>>,
  page: number
) {
  useEffect(() => {
    if (map.mapObject) {
      const mapObject = map.mapObject;
      map.mapObject.addListener('center_changed', () => {
        setCenter({
          lat: mapObject.getCenter().lat(),
          lng: mapObject.getCenter().lng(),
        });
      });
      map.mapObject.addListener('bounds_changed', () => {
        const bounds = mapObject.getBounds();
        if (bounds) setBoundsSize(calculateBoundsArea(bounds));
      });
      map.mapObject.addListener('zoom_changed', () => {
        setZoom(mapObject.getZoom());
      });
      setZoom(mapObject.getZoom());
      if (page >= 2) {
        map.mapObject?.setOptions(mapOptionsDisabled);
      }
    }
  }, [map.mapObject]);
}

function handleDisabledScrolling(
  page: number,
  map: MapWithMarkers,
  setLastPage: React.Dispatch<React.SetStateAction<number>>,
  lastPage: number,
  panels: google.maps.Polygon[],
  windowSize: { w: number; h: number },
  setWindowSize: React.Dispatch<React.SetStateAction<{ w: number; h: number }>>
) {
  const [forcedScroll] = useState({ value: false }); //is true during scrolling that we initiated
  const [resetScroll, setResetScroll] = useState(false); //is true when we need to fix scrolling after safari messes it up
  const [focusCounter] = useState({ value: 0 }); // used for keeping the scroll when an input is focused
  const windowSizeDebounced = useDebounce(windowSize, 1); // debounce to stop the browser from going crazy on resize

  useEffect(() => {
    //to fix scroll after resize
    window.addEventListener('resize', () => {
      if (focusCounter.value == 0 || isDesktop)
        setWindowSize({ w: window.innerWidth, h: window.innerHeight });
    });

    //to fix scroll after safari forces a scroll
    document.addEventListener('scroll', () => {
      if (!forcedScroll.value && focusCounter.value == 0) setResetScroll(true);
    });

    //to fix scroll after safari forces a scroll on input focus (but only after no focus on any input)
    document.addEventListener('focusin', () => {
      focusCounter.value += 1;
    });
    document.addEventListener('focusout', () => {
      focusCounter.value = 0;
      //timeout in case of a new focusin immediately after focusout
      setTimeout(() => {
        if (!forcedScroll.value && focusCounter.value == 0) setResetScroll(true);
      }, 100);
    });
  }, []);

  //on page change / resize / mitigate safari zooming and scrolling
  useEffect(() => {
    const pageId = 'page' + page;

    if (page == 1) {
      map.mapObject?.setOptions(mapOptions);
      scrollTo('page1', setLastPage, page, map, true, forcedScroll, resetScroll);
      scrollTo('split-panel', setLastPage, page, map, false, forcedScroll, resetScroll);
    } else if (page == 2) {
      scrollTo('split-panel', setLastPage, page, map, false, forcedScroll, resetScroll);
    } else {
      scrollTo(pageId, setLastPage, page, map, false, forcedScroll, resetScroll);
    }

    //given that we reuse the map, make sure that the correct elements are shown on it
    if (page < 2 && lastPage >= 2) {
      map.waveMarker?.setVisible(false);
      panels.forEach((panel) => panel.setMap(null));
    } else if (page >= 2) {
      map.marker?.setVisible(false);
      map.polygon?.setVisible(false);
      map.mapObject?.setOptions(mapOptionsDisabled);
      scrollTo('page2', setLastPage, page, map, true, forcedScroll, resetScroll);
    }

    if (resetScroll) {
      setResetScroll(false);
    }
  }, [page, windowSizeDebounced, resetScroll]);
}
