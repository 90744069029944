import { stringify } from 'query-string';

export type RequestContactInput = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  url: string;
};

export const contactPath = (input: RequestContactInput): string =>
  `/api/contact?${stringify({
    firstName: input.firstName,
    lastName: input.lastName,
    email: input.email,
    phone: input.phone,
    url: encodeURIComponent(input.url),
  })}`;
