import React from 'react';

interface Props {
  number: number;
}

export const CircledNumber: React.FC<Props> = ({ number }) => {
  return (
    <div className="w-5 h-5 rounded-full wh1:w-6 wh1:h-6 wh3:w-8 wh3:h-8 wh5:w-11 wh5:h-11 wh7:w-12 wh7:h-12 bg-secondary-600">
      <div className="w-full mt-0.5 wh3:mt-1.5 wh5:text-lg wh5:mt-2 wh7:text-2xl text-xs wh1:text-sm text-center text-white">
        {number}
      </div>
    </div>
  );
};
