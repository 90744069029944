import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
import HighchartMore from 'highcharts/highcharts-more';
import Highcharts from 'highcharts';

HighchartMore(Highcharts);

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export class KwhGraph extends Component {
  constructor(data: number[]) {
    super({});

    const series: {
      type: string;
      name: string;
      data: number[];
      visible: boolean;
      color: Highcharts.GradientColorObject;
    }[] = [];

    series.push({
      type: 'column',
      name: 'kWh',
      data: data,
      color: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, '#ffe775'], // start
          [0.5, '#ffaf63'], // middle
          [1, '#ff6242'], // end
        ],
      },
      visible: true,
    });

    this.state = {
      // To avoid unnecessary update keep all options in the state.
      chartOptions: {
        title: {
          text: undefined,
        },
        chart: {
          polar: false,
          backgroundColor: 'rgba(255,255,255,0)',
          height: '60%',
          spacing: [0, 0, 0, 0],
          marginTop: 0,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: months,
        },
        series: series,
        yAxis: {
          min: 0,
          endOnTick: false,
          showLastLabel: true,
          title: {
            enabled: false,
          },
          labels: {
            formatter: function () {
              //eslint-disable-next-line
              //@ts-ignore
              const value = this.value;
              if (value == 0) {
                return '';
              } else if (value / 1000 >= 1) {
                if (value / 1000000 >= 1) {
                  return value / 1000000 + 'GWh';
                }
                return value / 1000 + 'MWh';
              }
              return value + 'kWh';
            },
            style: {
              color: '#222222',
              fontSize: '13px',
              fontWeight: '900',
            },
            x: -5,
            y: 5,
          },
          reversedStacks: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y}</b><br/>`,
          valueDecimals: 0,
          followPointer: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.05,
            borderWidth: 0,
            groupPadding: 0,
            shadow: false,
          },
        },
      },
      hoverData: null,
    };
  }

  render(): JSX.Element {
    //eslint-disable-next-line
    //@ts-ignore
    const { chartOptions } = this.state;

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
  }
}
