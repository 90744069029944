import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import { contactPath } from '../../api/client/contactQuery';
import { BackButton } from '../atoms/BackButton';
import ContactInput from '../atoms/ContactInput';
import ContactPhoneInput from '../atoms/ContactPhoneInput';
import ReactGA from 'react-ga';
import prop from '../../utils/Prop';
import sjcl from 'sjcl';

type FormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

const Contact: React.FC = () => {
  const loc = useLocation();
  const navigate = useNavigate();

  const schema = Joi.object({
    firstName: Joi.string()
      .required()
      .max(100)
      .prefs({
        messages: {
          'string.empty': 'You must provide a name',
          'string.max': 'Name too long',
        },
      }),
    lastName: Joi.string()
      .required()
      .max(100)
      .prefs({
        messages: {
          'string.empty': 'You must provide a name',
          'string.max': 'Name too long',
        },
      }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .max(100)
      .prefs({
        messages: {
          'string.empty': 'You must provide an e-mail',
          'string.max': 'E-mail too long',
          'string.email': 'Not a valid e-mail',
        },
      }),
    phone: Joi.string()
      .required()
      .max(20)
      .prefs({
        messages: {
          'string.empty': 'You must provide a phone number',
          'string.max': 'Phone number is too long',
        },
      }),
  });

  const { control, handleSubmit, formState } = useForm<FormInputs>({
    resolver: joiResolver(schema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    shouldUnregister: false,
    mode: 'onBlur',
  });

  const { error, data, get } = useFetch(
    undefined, // Query same domain as we're serving from.
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );
  return (
    <>
      <div className="flex flex-col items-center w-full h-screen pb-32 -mt-16 wh1:pb-32 wh1:-mt-32">
        <BackButton
          onClick={() => {
            const searchParams = new URLSearchParams(loc.search);
            searchParams.delete('page');
            searchParams.append('page', '2');
            navigate('/?' + searchParams.toString());
          }}
          label="Back to quote"
        />
        {!(data && !error) && (
          <form
            className="h-full"
            onSubmit={handleSubmit(({ firstName, lastName, email, phone }) => {

              if (prop.ga) {
                const hashedEmail = sjcl.codec.hex.fromBits(sjcl.hash.sha256.hash(email)).slice(0, 12);
                ReactGA.set({ userId: hashedEmail });
                ReactGA.event({
                  category: 'User',
                  action: 'Request Quote'
                });
                console.log('ReactGA.event(User ' + hashedEmail + ' request quote)');
              }

              get(
                contactPath({
                  firstName: firstName,
                  lastName: lastName,
                  email: email,
                  phone: '+' + phone.replace(/\s+/g, ''),
                  url: location.href
                    .replace('page=4', 'page=2')
                    .replace('localhost:3000', 'quote.sunlitsea.no'),
                })
              );
            })}
          >
            <div className="flex flex-col h-full justify-evenly flex-grow-no-shrink w-80 wh1:w-96 wh5:w-120">
              <h2 className="pb-1 text-lg text-center wh1:text-xl wh3:px-1 wh3:text-2xl wh5:text-4xl">
                Fill out this form and we will contact you
              </h2>
              <ContactInput control={control} errors={formState.errors} id="firstName" label="First name" />
              <ContactInput control={control} errors={formState.errors} id="lastName" label="Last name" />
              <ContactPhoneInput
                control={control}
                errors={formState.errors}
                id="phone"
                label="Phone with country code"
              />
              <ContactInput control={control} errors={formState.errors} id="email" label="E-mail" />
              <div className="flex flex-col items-center w-full">
                {error && (
                  <span className="w-full text-xs text-center text-alert font-arial">
                    Contact information was not accepted. Please ensure there are no special
                    characters in the fields.
                  </span>
                )}
                <button
                  className={`px-6 w-24 py-1 wh1:py-2 mb-2 wh4:mb-5 wh5:mb-8 border-transparent rounded-lg mt-2  focus:outline-none active:border-transparent 
            ${true
                      ? 'bg-secondary-600 hover:bg-secondary-800 focus:ring-1 focus:ring-secondary-700'
                      : 'bg-gray-400 cursor-default'
                    }`}
                  type="submit"
                >
                  <span className="text-base text-white wh1:text-lg">Send</span>
                </button>
              </div>
            </div>
          </form>
        )}
        {data && !error && (
          <div className="flex flex-col my-auto w-96">
            <h2 className="text-4xl text-center w-96">Thank you!</h2>
            <h2 className="mt-12 text-4xl text-center w-96">We will reach out to you soon</h2>
          </div>
        )}
      </div>
    </>
  );
};

export default Contact;
