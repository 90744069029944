import React from 'react';
import { UseFetch } from 'use-http';

import { WaveQueryResponse } from '../../api/client/waveQuery';
import QuestionMark from '../atoms/QuestionMark';
import { Modal } from './Modal';
import { PolarChart } from './PolarChart';

type Props = {
  waveQuery: UseFetch<WaveQueryResponse>;
  waverose: PolarChart | undefined;
  hasLake: boolean;
  hasSea: boolean;
  wavePositionChanged: boolean;
};

export const WaveChart: React.FC<Props> = ({
  waveQuery,
  hasLake,
  hasSea,
  waverose,
  wavePositionChanged,
}) => {
  const hasWave =
    waveQuery.data &&
    waveQuery.data.returnPeriodWaves &&
    waveQuery.data.waveIntervals &&
    waveQuery.data.waveFreq &&
    waveQuery.data.returnPeriodWaves.length > 0 &&
    waveQuery.data.waveFreq.length > 0 &&
    waveQuery.data.waveIntervals.length > 0;
  return (
    <>
      {(waveQuery.error && (
        <div className="w-full mx-auto mt-10 text-lg text-center">
          Environmental data is currently unavailable for this location.
        </div>
      )) ||
        ((waveQuery.loading || (!waveQuery.data && !waveQuery.error)) && (
          <div className="flex flex-col max-w-screen-lg mx-auto mt-20">
            <div className="mx-auto text-xl">Calculating wave data</div>
            <div className="mx-auto mt-4">
              <img src="/img/spinner.svg" alt="Loading" />
            </div>
          </div>
        )) ||
        (!hasSea && !hasLake && (
          <div className="w-full mx-auto mt-10 text-lg text-center">
            Our datasets do not contain water bodies for this location
          </div>
        )) ||
        (!hasWave && (
          <div className="w-full mx-auto mt-10 text-lg text-center">
            Wave data for this location is currently unavailable
          </div>
        )) ||
        (waveQuery.data && !waveQuery.error && (
          <>
            <div className="flex flex-col items-center w-full max-h-full mt-2">
              <Modal
                title="Waves"
                text={
                  'The wave chart presents hourly wind-generated wave heights from the 2014-2020 period based on the wind data. ' +
                  'The chart maps the data as incoming waves, meaning sector 0 shows the waves coming from the north. ' +
                  'The data is grouped into categories based on the wave heights. Individual categories can be toggled by clicking on the legend. The first category is toggled off by default to hide the smallest waves. ' +
                  'Below the chart are the highest expected waves for given time spans, that were extrapolated from the data.'
                }
                trigger={
                  <button
                    type="button"
                    className="z-10 flex flex-row leading-none wh0:leading-tight text-gray-800 items-center wh0:pb-0.5 wh2:pb-2 text-base wh0:text-lg mx-auto wh2:text-xl wh4:text-3xl wh6:text-4xl underline hover:cursor-pointer focus:outline-none active:border-transparent focus:ring-1 focus:ring-gray-400"
                  >
                    <span className="pr-1">Wave Chart</span>
                    <span className="inline-block">
                      <QuestionMark className="w-3 h-3 wh0:w-4 wh0:h-4 wh4:h-6 wh4:w-6 wh6:h-7 wh6:w-7" />
                    </span>
                  </button>
                }
              />
              <div className="relative max-h-full mx-auto w-72 wh0:w-96 wh4:w-156 wh7:w-120">
                {waverose?.render()}
              </div>
              <div className="flex flex-row mx-8 leading-tight text-center text-xs2 wh0:text-xs wh0:flex-col wh1:text-sm wh0:w-56 wh5:text-base wh3:mt-2">
                <div className="mx-1">
                  5-year wave: {waveQuery.data.returnPeriodWaves[0].toFixed(2)}m
                </div>
                <div className="mx-1">
                  10-year wave: {waveQuery.data.returnPeriodWaves[1].toFixed(2)}m
                </div>
                <div className="mx-1">
                  25-year wave: {waveQuery.data.returnPeriodWaves[2].toFixed(2)}m
                </div>
                <div className="mx-1">
                  50-year wave: {waveQuery.data.returnPeriodWaves[3].toFixed(2)}m
                </div>
              </div>
              {wavePositionChanged && (
                <div className="w-full mx-auto mt-1 text-xs text-center wh3:mt-10 wh4:px-10 wh3:text-base wh4:text-lg">
                  Our datasets do not contain water at the center of the installation, so the wave
                  data was calculated for the point shown on the map with a marker.
                </div>
              )}
            </div>
          </>
        ))}
    </>
  );
};
