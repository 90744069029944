import React, { useEffect, useState } from 'react';
import useDebounce from '../../hooks/useDebounce';

type Props = {
  zoom: number;
  center: { lat: number; lng: number };
  page: number;
  zoomService: google.maps.MaxZoomService | null;
};

export const MissingImagery: React.FC<Props> = ({ zoom, center, page, zoomService }) => {
  const [imageryPresent, setImageryPresent] = useState(true);
  const zoomDebounced = useDebounce<number | undefined>(zoom, 500);
  const centerDebounced = useDebounce<{ lat; lng }>(center, 500);
  useEffect(() => {
    if (page == 1) {
      if (zoomService && centerDebounced && zoomDebounced) {
        zoomService.getMaxZoomAtLatLng(center, (result: google.maps.MaxZoomResult) => {
          if (result.status !== 'OK') {
            console.log('Error in MaxZoomService');
          } else {
            //adding 2 corresponds to the zoom where google maps actually shows the "sorry, we have no imagery here" tiles
            setImageryPresent(result.zoom + 2 >= zoomDebounced);
          }
        });
      }
    } else if (!imageryPresent) setImageryPresent(true);
  }, [centerDebounced, zoomDebounced]);
  return (
    <>
      {!imageryPresent && (
        <div className="absolute z-30 w-full pointer-events-none wh0:w-7/12 ">
          <div className="p-1 mx-auto mt-2 text-center rounded-lg wh0:p-2 w-60 wh0:w-48 bg-support-dark">
            <div className="text-xs leading-4 text-white wh0:text-sm ">
              ⚠ There seems to be no imagery from Google Maps at this location, but you may still
              calculate a quote here.
            </div>
          </div>
        </div>
      )}
    </>
  );
};
