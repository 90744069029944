import { useGoogleMapsReady } from './google-maps';

let _geocodeService: google.maps.Geocoder | null = null;

export const useGeocoderService: () => google.maps.Geocoder | null = () => {
  const mapsReady = useGoogleMapsReady();
  if (_geocodeService !== null) {
    return _geocodeService;
  }
  if (!mapsReady) {
    return null;
  }
  _geocodeService = new google.maps.Geocoder();
  return _geocodeService;
};
