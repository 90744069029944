import React, { useEffect, useState } from 'react';

import NumberFormat from 'react-number-format';
import { joiResolver } from '@hookform/resolvers/joi';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useForm } from 'react-hook-form';
import Joi from 'joi';

import LocationInput from '../molecules/LocationInput';
import { Tooltip } from '../atoms/Tooltip';
import VSymbol from '../atoms/VSymbol';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../molecules/Header';
import { useGeocoderService } from '../../api/google-maps/useGeocoderService';

type FormInputs = {
  consumption: number;
  place: {
    name: string;
    placeId: string;
  };
  kwhMode: boolean;
};

// Validation scheme for the form
const schema = Joi.object({
  consumption: Joi.number()
    .min(0.5)
    .when('kwhMode', { is: true, then: Joi.number().max(20000000000) })
    .when('kwhMode', { is: false, then: Joi.number().max(10000000) })
    .required()
    .prefs({
      messages: {
        'number.base': 'You must provide your yearly energy consumption',
        'number.min': 'Your energy consumption must be at least 0.5',
        'number.max':
          'The requested solar power plant size is too large. Please contact Sunlit Sea for further inquiries.',
      },
    }),

  place: Joi.object({
    name: Joi.string().optional().allow('').allow(null),
    placeId: Joi.string().optional().allow('').allow(null),
  })
    .optional()
    .and('name', 'placeId')
    .prefs({
      messages: {
        'object.and': 'Please select a location from the dropdown or leave the field blank',
      },
    }),

  kwhMode: Joi.boolean().required(),
});

const Inquiry: React.FC = () => {
  const loc = useLocation();
  const navigate = useNavigate();
  const [locationName, setLocationName] = useState<string | null>(null);
  const [kwhMode, setKwhConsumption] = useState(
    (new URLSearchParams(loc.search).get('kwhMode') ?? 'true') === 'true'
  );

  //retrieve the location name from the place id to put into the input field
  const geocoder = useGeocoderService();
  useEffect(() => {
    const place = new URLSearchParams(loc.search).get('place');
    if (place) {
      geocoder?.geocode({ placeId: place }, (results, status) => {
        if (status === 'OK' && results[0]) {
          setLocationName(results[0].formatted_address);
        }
      });
    }
  }, [geocoder]);

  // Setup form validation helpers
  const { control, handleSubmit, formState, clearErrors } = useForm<FormInputs>({
    resolver: joiResolver(schema),
    defaultValues: {
      consumption: Number(new URLSearchParams(loc.search).get('consumption') ?? 0),
      place: {
        placeId: new URLSearchParams(loc.search).get('place') ?? undefined,
        name: locationName ?? undefined,
      },
      kwhMode: kwhMode,
    },
    shouldUnregister: false,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  return (
    <>
      <section className="flex flex-col w-full h-screen pb-20 flex-grow-no-shrink wh1:pb-32">
        <Header />
        <form
          onSubmit={handleSubmit(
            ({ consumption, place }) => {
              const searchParams = new URLSearchParams();
              if (place.placeId) searchParams.append('place', place.placeId);
              searchParams.append('consumption', consumption.toString());
              searchParams.append('kwhMode', kwhMode.toString());
              searchParams.append('page', '1');
              navigate('/?' + searchParams.toString());
            },
            (e) => console.log(e)
          )}
          className="flex w-full h-full "
        >
          <div className="flex flex-col items-center mx-auto my-auto w-80 wh0:w-96 wh5:w-120 wh5:px-6 wh4:pb-20">
            <h1 className="mb-2 text-xl wh1:text-3xl wh3:text-4xl wh3:mb-4 wh5:text-6xl wh5:mb-6">
              Smart quoter
            </h1>

            <VSymbol className="w-3 h-3 wh5:w-8 wh5:h-8 wh1:w-5 wh1:h-5" />

            <div className="flex flex-row justify-center w-full mt-4 wh5:mt-6 mb-2.5">
              <label
                className="text-sm font-medium leading-tight wh1:text-base wh5:text-xl pr-1.5"
                htmlFor="consumption"
              >
                Yearly energy consumption
              </label>
              <Tooltip
                tooltipID="consumption"
                message="Check your electricity bill to find your yearly energy consumption."
              />
            </div>
            <Controller
              render={({ field }) => (
                <>
                  <div className="w-full text-sm wh5:text-lg font-arial whitespace-nowrap">
                    <NumberFormat
                      id="consumption"
                      thousandSeparator=" "
                      className={
                        'w-full pl-4 pr-10 font-normal rounded h-8 wh1:h-11 wh5:h-14 wh5:rounded-lg bg-secondary-50 focus:outline-none focus:ring-1 focus:ring-secondary-300'
                      }
                      value={field.value === 0 ? '' : field.value}
                      onChange={(e) => field.onChange(e.currentTarget.value.replace(/ /g, ''))}
                      onBlur={field.onBlur}
                      getInputRef={field.ref}
                      onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                          e.preventDefault();
                        }
                      }}
                    />
                    <label
                      className={`-ml-10 wh5:-ml-12 font-bold text-base wh5:text-xl ${kwhMode ? 'text-secondary-700' : 'text-primary-500'
                        }`}
                    >
                      {kwhMode ? 'kWh' : 'kWp'}
                    </label>
                  </div>
                  <Controller
                    render={({ field: field2 }) => (
                      <div className="flex flex-row justify-end w-full">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setKwhConsumption(!kwhMode);
                            field2.onChange(!kwhMode);
                            field.onBlur();
                          }}
                          className="mt-2 text-sm text-right text-gray-600 underline rounded-lg wh5:text-lg hover:cursor-pointer focus:outline-none active:border-transparent focus:ring-1 focus:ring-secondary-300"
                        >
                          Switch to {kwhMode ? 'kWp' : 'kWh'}?
                        </button>
                      </div>
                    )}
                    name="kwhMode"
                    control={control}
                  />
                </>
              )}
              name="consumption"
              control={control}
            />
            <ErrorMessage
              errors={formState.errors}
              name="consumption"
              render={({ message }) => (
                <p className="w-full mt-1 text-xs wh1:mt-2 wh1:text-sm wh5:text-lg text-alert font-arial">
                  {message}
                </p>
              )}
            />

            <div className="flex flex-row justify-center w-full mt-9 mb-2.5">
              <label
                className="text-sm font-medium leading-tight wh1:text-base wh5:text-xl pr-1.5"
                htmlFor="location"
              >
                Location for installation (optional)
              </label>
              <Tooltip
                tooltipID="location"
                message="You can find a location where you would like to place the installation, or leave the field blank to show the whole world map. The location will not be shared with Sunlit Sea until you contact us."
              />
            </div>

            <Controller
              render={({field}) => (
                <LocationInput
                  clearErrors={() => clearErrors('place')}
                  id="placeId"
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={field.value}
                  previousValue={locationName}
                />
              )}
              name="place"
              control={control}
            />
            <ErrorMessage
              errors={formState.errors}
              name="place"
              render={({ message }) => (
                <p className="w-full mt-1 text-xs wh1:mt-2 wh1:text-sm wh5:text-lg text-alert font-arial">
                  {message}
                </p>
              )}
            />

            <button
              className={`wh5:mt-12 px-6 py-2 border-transparent rounded-lg mt-8 focus:outline-none active:border-transparent 
            ${true
                  ? 'bg-secondary-600 hover:bg-secondary-800 focus:ring-1 focus:ring-secondary-900'
                  : 'bg-gray-400 cursor-default'
                }`}
              type="submit"
            >
              <span className="text-xl text-white wh5:text-2xl">Get quote</span>
            </button>
          </div>
        </form>
      </section>
    </>
  );
};

export default Inquiry;
