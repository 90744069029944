import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import './styles/index.scss';
import ReactGA from 'react-ga';
import prop from './utils/Prop';

const container = document.getElementById('root');
// eslint-disable-next-line
const root = createRoot(container!);
if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID && process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID !== 'NOT_SET') {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
    prop.ga = true;
    console.log('ga.initialize');
}
root.render(<App />);

reportWebVitals();
