import React from 'react';
import Popup from 'reactjs-popup';

type Props = {
  title: string;
  text: string;
  trigger: JSX.Element | ((isOpen: boolean) => JSX.Element) | undefined;
};

export const Modal: React.FC<Props> = ({ title, text, trigger }) => (
  <Popup trigger={trigger} modal nested
    onClose=
      {(close) => (
      <div className="flex flex-col max-w-screen-md mx-2 bg-white rounded-md">
        <button
          className="self-end mr-4 text-4xl focus:outline-none active:border-transparent"
          onClick={() => close}
        >
          &times;
        </button>
        <h2 className="pl-4 lg:pl-10"> {title} </h2>
        <div className="px-4 pb-8 mt-4 lg:px-10">{text}</div>
      </div>
    )}>
  </Popup>
);
