import { stringify } from 'query-string';

export type RequestQuoteManualInput = {
  consumption: number;
  kwhMode: boolean;
  lat: number;
  lng: number;
  north: number;
  south: number;
  east: number;
  west: number;
};

export type QuoteManualResponse = {
  kwp: number;
  installationCost: number;
  irr: number;
  npv: number;
  kwhPerYear: number;
  co2Reduction: number;
  tenYearSaving: number;
  hasSea: boolean;
  hasLake: boolean;
  x: number;
  y: number;
  extraPanels: number;
  coverage: number;
};

export const quoteManualPath = (input: RequestQuoteManualInput): string =>
  `/api/quotemanual?${stringify({
    lat: input.lat,
    lng: input.lng,
    consumption: input.consumption,
    kwhmode: input.kwhMode ? 1 : 0,
    north: input.north,
    south: input.south,
    east: input.east,
    west: input.west,
  })}`;
