import React, { useEffect, useState } from 'react';

import { WaveQueryResponse } from '../../api/client/waveQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import { UseFetch } from 'use-http';
import { PolarChart } from '../molecules/PolarChart';
import { BackButton } from '../atoms/BackButton';
import { MapWithMarkers } from '../../api/google-maps/useMap';
import { MonthlyKwhResponse } from '../../api/client/monthlyKwhQuery';
import { WindChart } from '../molecules/WindChart';
import { WaveChart } from './../molecules/WaveChart';
import Chevron from '../atoms/Chevron';
import { Element, scroller } from 'react-scroll';
import { KwhGraph } from '../molecules/KwhGraph';
import { MonthlyKwhChart } from '../molecules/MonthlyKwhChart';
import ReactGA from 'react-ga';
import prop from '../../utils/Prop';

type Props = {
  map: MapWithMarkers;
  monthlyKwhQuery: UseFetch<MonthlyKwhResponse>;
  waveQuery: UseFetch<WaveQueryResponse>;
  hasSea: boolean;
  hasLake: boolean;
  windowSize: { w: number; h: number };
};

type ScrollOptions = {
  duration: number;
  delay: number;
  smooth: string | boolean;
  containerId: null | string;
  ignoreCancelEvents: boolean;
  horizontal: boolean;
};

const lastGraph = 2;
const cgSet = new Set<number>();

const Environment: React.FC<Props> = ({
  map,
  monthlyKwhQuery,
  waveQuery,
  hasSea,
  hasLake,
  windowSize,
}) => {
  const loc = useLocation();
  const navigate = useNavigate();

  //if (prop.ga) {
  //  console.log('pageview(/page/3/0)');
  //  ReactGA.pageview('/page/3/0');
  //}
  const gaOnce = (cg: number) => {
    if (prop.ga) {
      if (!cgSet.has(cg)) {
        cgSet.add(cg);
        console.log('pageview(/page/3/' + cg + ')');
        ReactGA.pageview('/page/3/' + cg);
      }
    }
  };
  gaOnce(0);
  const [currentGraph, setCurrentGraphInner] = useState(0);
  const setCurrentGraph = (cg: number) => {
    gaOnce(cg);
    setCurrentGraphInner(cg);
  }

  useEffect(() => {
    const options: ScrollOptions = {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuad',
      containerId: 'graph-container',
      ignoreCancelEvents: true,
      horizontal: true,
    };
    scroller.scrollTo('graph-' + currentGraph, options);
  }, [currentGraph, windowSize]);

  const [windrose, setWindrose] = useState<PolarChart | undefined>(undefined);
  const [waverose, setWaverose] = useState<PolarChart | undefined>(undefined);
  if (waveQuery.data && !waveQuery.error && !waveQuery.loading && (!windrose || !waverose)) {
    setWindrose(new PolarChart(waveQuery.data.windFreq, waveQuery.data.windIntervals, 'wind'));
    setWaverose(new PolarChart(waveQuery.data.waveFreq, waveQuery.data.waveIntervals, 'wave'));
  }

  let wavePositionChanged = false;
  if (waveQuery.data && map.mapObject) {
    wavePositionChanged =
      Math.abs(waveQuery.data.lat - map.mapObject.getCenter().lat()) > 0.0001 ||
      Math.abs(waveQuery.data.lng - map.mapObject.getCenter().lng()) > 0.0001;
  }

  const [kwhGraph, setKwhGraph] = useState<KwhGraph | undefined>(undefined);
  if (monthlyKwhQuery.data && !monthlyKwhQuery.error && !monthlyKwhQuery.loading && !kwhGraph) {
    setKwhGraph(new KwhGraph(monthlyKwhQuery.data.monthlyKwh));
  }

  return (
    <>
      <div className="flex flex-col items-center justify-between w-full h-screen pb-24 -mt-16 wh0:-mt-20 wh0:pb-20 wh1:pb-32 wh1:-mt-32">
        <BackButton
          onClick={() => {
            const searchParams = new URLSearchParams(loc.search);
            searchParams.delete('page');
            searchParams.append('page', '2');
            navigate('/?' + searchParams.toString());
          }}
          label="Back to quote"
        />
        <div className="flex flex-row justify-center w-screen">
          {currentGraph != 0 && (
            <button
              className="absolute left-0 my-auto mt-48 wh0:ml-4 wh7:invisible wh7:pointer-events-none"
              onClick={() => {
                setCurrentGraph(currentGraph - 1 < 0 ? lastGraph : currentGraph - 1);
              }}
            >
              <Chevron.LeftWide className="z-50 w-10 h-10 transform wh0:w-14 wh0:h-14" />
              <div className="w-10 mt-1 text-sm text-center wh0:text-base wh0:w-14">
                Previous chart
              </div>
            </button>
          )}
          <div className="w-full -mt-6">
            <Element
              name="graph-container"
              id="graph-container"
              className="flex flex-row items-center w-screen mt-2 overflow-hidden"
            >
              <Element
                name="graph-0"
                id="graph-0"
                className="flex w-screen wh7:w-1/3 flex-grow-no-shrink"
              >
                <MonthlyKwhChart kwhChart={kwhGraph} kwhQuery={monthlyKwhQuery} />
              </Element>
              <Element
                name="graph-1"
                id="graph-1"
                className="flex w-screen wh7:w-1/3 flex-grow-no-shrink"
              >
                <WindChart waveQuery={waveQuery} windrose={windrose} />
              </Element>
              <Element
                name="graph-2"
                id="graph-2"
                className="flex w-screen wh7:w-1/3 flex-grow-no-shrink"
              >
                <WaveChart
                  waveQuery={waveQuery}
                  waverose={waverose}
                  hasLake={hasLake}
                  hasSea={hasSea}
                  wavePositionChanged={wavePositionChanged}
                />
              </Element>
            </Element>
          </div>
          {currentGraph != lastGraph && (
            <button
              className="absolute right-0 my-auto mt-48 mr-0 wh0:mr-4 wh7:invisible wh7:pointer-events-none"
              onClick={() => {
                setCurrentGraph(currentGraph + 1 > lastGraph ? 0 : currentGraph + 1);
              }}
            >
              <Chevron.LeftWide className="z-50 w-10 h-10 transform rotate-180 wh0:w-14 wh0:h-14" />
              <div className="w-10 pr-2 mt-1 text-sm text-center wh0:text-base wh0:w-14">
                Next chart
              </div>
            </button>
          )}
        </div>
        <div className="flex flex-col items-center w-full pb-6 wh3:mt-4">
          <h2 className="px-4 text-sm text-center wh1:text-base wh3:text-xl ">
            Get in touch with us to get a detailed quote
          </h2>
          <button
            className={`py-1 px-4 wh3:px-6 wh3:py-2 border-transparent rounded-lg mt-1 wh3:mt-2 w-36  mx-auto focus:outline-none active:border-transparent bg-secondary-600 hover:bg-secondary-800 focus:ring-1 focus:ring-secondary-700`}
            type="button"
            onClick={() => {
              const searchParams = new URLSearchParams(loc.search);
              searchParams.delete('page');
              searchParams.append('page', '4');
              navigate('/?' + searchParams.toString());
            }}
          >
            <span className="text-base text-white wh3:text-lg">Continue</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Environment;
