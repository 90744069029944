import React from 'react';
import { UseFetch } from 'use-http';
import { MonthlyKwhResponse } from '../../api/client/monthlyKwhQuery';

import QuestionMark from '../atoms/QuestionMark';
import { KwhGraph } from './KwhGraph';
import { Modal } from './Modal';

type Props = {
  kwhChart: KwhGraph | undefined;
  kwhQuery: UseFetch<MonthlyKwhResponse>;
};

export const MonthlyKwhChart: React.FC<Props> = ({ kwhChart, kwhQuery }) => {
  return (
    <>
      {(kwhQuery.error && (
        <div className="w-full mx-auto mt-10 text-lg text-center">
          Monthly production estimate is currently unavailable.
        </div>
      )) ||
        (!kwhChart && (
          <div className="flex flex-col max-w-screen-lg mx-auto mt-20">
            <div className="mx-auto text-xl">Calculating monthly production estimate</div>
            <div className="mx-auto mt-4">
              <img src="/img/spinner.svg" alt="Loading" />
            </div>
          </div>
        )) || (
          <>
            <div className="flex flex-col items-center w-full max-h-full ">
              <Modal
                title="Energy production estimate"
                text={
                  'The energy production estimate is calculated based on the peak power of the installation (in kWp), solar radiation, and a performance ratio of 0.9. ' +
                  'Solar radiation data is extracted from the NASA POWER dataset for the years 2000-2020, which gives us average monthly and yearly radiation in W/m^2 in a 1°x1° grid resolution. ' +
                  'Performance ratio of 0.9 assumes optimal conditions and does not include detailed analysis of soiling, degradation, or shading, among others. ' +
                  'Detailed analysis of energy production will be performed in PVsyst on request to produce a more accurate estimate.'
                }
                trigger={
                  <button
                    type="button"
                    className="z-10 flex flex-row items-center pb-2 mx-auto text-base leading-none text-gray-800 underline wh0:leading-tight wh0:text-lg wh2:text-xl wh4:text-3xl wh6:text-4xl hover:cursor-pointer focus:outline-none active:border-transparent focus:ring-1 focus:ring-gray-400"
                  >
                    <span className="pr-1">Energy Production Estimate</span>
                    <span className="inline-block">
                      <QuestionMark className="w-3 h-3 wh0:w-4 wh0:h-4 wh4:h-6 wh4:w-6 wh6:h-7 wh6:w-7" />
                    </span>
                  </button>
                }
              />
              <div className="relative max-h-full mx-auto w-72 wh0:w-96 wh4:w-156 wh7:w-120">
                {kwhChart?.render()}
              </div>
            </div>
          </>
        )}
    </>
  );
};
