import * as geolib from 'geolib';
import { MatrixSize, panelSideLength, panelSpacing, tileSize } from './MatrixSize';

export const usePanelCoordinates = (
  installationStartPoint: google.maps.LatLngLiteral,
  installationOrientation: number,
  panelColumn: number,
  panelRow: number
): null | google.maps.LatLngLiteral[] => {
  const startPoint = computeDestinationPoint(
    computeDestinationPoint(
      installationStartPoint,
      distanceFromStartPoint(panelColumn),
      (270 + installationOrientation) % 360
    ),
    distanceFromStartPoint(panelRow),
    installationOrientation % 360
  );

  const directions = [180, 90, 0].map((direction) => {
    return (direction + installationOrientation) % 360;
  });

  const coords = [startPoint];
  for (let index = 0; index < 3; index++) {
    //using tile size instead of panel side lenght to make it look nicer
    coords.push(computeDestinationPoint(coords[index], tileSize, directions[index]));
  }

  return coords;
};

const distanceFromStartPoint = (panelOffset: number) => {
  return (panelOffset + 1) * panelSideLength + panelOffset * panelSpacing + panelSpacing / 2;
};

export const computePolygonPaths = (
  center: { lat; lng },
  matrixSize: MatrixSize,
  rotation: number
): Array<{ lat; lng }> => {
  return [
    computeDiagonalPoint(center, matrixSize.height / 2, matrixSize.width / 2, 0 + rotation),
    computeDiagonalPoint(center, matrixSize.width / 2, matrixSize.height / 2, 90 + rotation),
    computeDiagonalPoint(center, matrixSize.height / 2, matrixSize.width / 2, 180 + rotation),
    computeDiagonalPoint(center, matrixSize.width / 2, matrixSize.height / 2, 270 + rotation),
  ];
};

export const computePolygonPathsDirect = (
  center: { lat; lng },
  matrixSize: { width: number; height: number },
  rotation: number
): Array<{ lat; lng }> => {
  return [
    computeDiagonalPoint(center, matrixSize.height / 2, matrixSize.width / 2, 0 + rotation),
    computeDiagonalPoint(center, matrixSize.width / 2, matrixSize.height / 2, 90 + rotation),
    computeDiagonalPoint(center, matrixSize.height / 2, matrixSize.width / 2, 180 + rotation),
    computeDiagonalPoint(center, matrixSize.width / 2, matrixSize.height / 2, 270 + rotation),
  ];
};

export function calculateBoundsArea(bounds: google.maps.LatLngBounds): number {
  const northEast = {
    latitude: bounds.getNorthEast().lat(),
    longitude: bounds.getNorthEast().lng(),
  };
  const southWest = {
    latitude: bounds.getSouthWest().lat(),
    longitude: bounds.getSouthWest().lng(),
  };
  const northWest = {
    latitude: northEast.latitude,
    longitude: southWest.longitude,
  };
  const southEast = {
    latitude: southWest.latitude,
    longitude: northEast.longitude,
  };
  const boundsSize = geolib.getAreaOfPolygon([
    northEast,
    northWest,
    southWest,
    southEast,
    northEast,
  ]);
  return boundsSize;
}

export const computeDiagonalPoint = (
  start: {
    lat: number;
    lng: number;
  },
  latDist: number,
  lngDist: number,
  bearing: number
): {
  lat: number;
  lng: number;
} => {
  return computeDestinationPoint(
    computeDestinationPoint(start, latDist, bearing),
    lngDist,
    (bearing + 270) % 360
  );
};

export const computeDestinationPoint = (
  start: {
    lat: number;
    lng: number;
  },
  distance: number,
  bearing: number
): {
  lat: number;
  lng: number;
} => longToShortForm(geolib.computeDestinationPoint(shortToLongForm(start), distance, bearing));

const shortToLongForm = ({ lat, lng }: { lat: number; lng: number }) => ({
  latitude: lat,
  longitude: lng,
});

const longToShortForm = ({ latitude, longitude }: { latitude: number; longitude: number }) => ({
  lat: latitude,
  lng: longitude,
});
