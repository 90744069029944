import React, { useEffect } from 'react';

export const useGoogleMapsReady = (): boolean => {
  const [gmaps, setGmaps] = React.useState<typeof google.maps | null>(null);

  useEffect(() => {
    loadGoogleMaps().then(setGmaps);
  }, []);

  return !!gmaps;
};

let LOAD_STATUS: 'NOT_LOADED' | Promise<typeof google.maps> | 'LOADED' =
  'NOT_LOADED';

/**
 * instead of `npm install google-maps` we do this
 *
 * It appears that google-maps are not provided in npm, and this is the only
 * way
 */
export const loadGoogleMaps: () => Promise<typeof google.maps> = () => {
  if (LOAD_STATUS === 'LOADED') {
    return Promise.resolve(google.maps);
  }

  if (LOAD_STATUS === 'NOT_LOADED') {
    LOAD_STATUS = new Promise<typeof google.maps>((resolve) => {
      // Create the script tag, set the appropriate attributes
      const script = document.createElement('script');

      const GOOGLE_API_KEY = 'AIzaSyDos5I40L8JgOfIkfH92VJQwgYxD_2u46Q';

      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&callback=initMap&libraries=places`;
      script.defer = true;

      // Attach your callback function to the `window` object
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).initMap = function () {
        console.log('Google Maps is loaded and available');
        LOAD_STATUS = 'LOADED';
        resolve(google.maps);
      };

      document.head.appendChild(script);
    });

    return LOAD_STATUS;
  }
  return LOAD_STATUS;
};
