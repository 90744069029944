import React from 'react';
import Chevron from './Chevron';

type Props = {
  label: string;
  onClick: () => void;
};

export const BackButton: React.FC<Props> = ({ onClick, label }) => {
  return (
    <div className="z-10 w-full pl-2 wh1:pl-6 wh1:mt-4">
      <button
        className="flex flex-row pt-1.5 hover:cursor-pointer active:border-transparent focus:outline-none focus:ring-1 focus:ring-gray-400"
        type="button"
        onClick={onClick}
      >
        <Chevron.Up className="inline w-4 mt-1 mr-1 wh5:w-6 wh5:mt-2 wh7:mr-2 wh7:h-4" />
        <span className="text-sm wh5:text-lg wh7:text-2xl">{label}</span>
      </button>
    </div>
  );
};
